export const CircleArrowRight = () => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18.5842" r="18" fill="white" />
      <path
        d="M24.5657 19.5498C24.8781 19.2374 24.8781 18.7309 24.5657 18.4184L19.4745 13.3273C19.1621 13.0149 18.6556 13.0149 18.3431 13.3273C18.0307 13.6397 18.0307 14.1462 18.3431 14.4586L22.8686 18.9841L18.3431 23.5096C18.0307 23.822 18.0307 24.3286 18.3431 24.641C18.6556 24.9534 19.1621 24.9534 19.4745 24.641L24.5657 19.5498ZM12 19.7841H24V18.1841H12V19.7841Z"
        fill="black"
      />
    </svg>
  );
};
