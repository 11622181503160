export const Polygon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10297 0.52002L11.4458 9.77408H0.760133L6.10297 0.52002Z"
        fill="currentColor"
      />
    </svg>
  );
};
