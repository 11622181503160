export const AccordionItem = ({ selectedItem, index, isfirst, onClick, data }) => {
  return (
    <div className="flex flex-col">
      <div
        className={`bg-[#182A3E] px-5 py-2 flex cursor-pointer flex-row items-center justify-between ${
          isfirst ? "rounded-t-[10px]" : ""
        }`}
        onClick={() => onClick(index)}
      >
        <div className="flex flex-row gap-[14px] text-white items-center">
          <p className="text-[20px]">{selectedItem === index ? "-" : "+"}</p>
          <p className="roboto-medium text-[16px] leading-[24px]">Period</p>
        </div>
        <p className="roboto-regular text-[16px] leading-[24px] text-white">
          {data.period}
        </p>
      </div>
      <div
        className={`bg-white transition-all ${
          selectedItem === index
            ? "px-[50px] py-[20px]"
            : "h-0 pt-[1px] overflow-hidden"
        } `}
      >
        <div className="flex flex-col mx-auto gap-2 w-[233px]">
          <div className="flex flex-row justify-between">
            <p className="text-black roboto-medium text-[16px] leading-[24px]">
              Net Profit
            </p>
            <p className="text-black roboto-regular text-[16px] leading-[24px]">
              {data.netprofit}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-black roboto-medium text-[16px] leading-[24px]">
              % Gain
            </p>
            <p className="text-black roboto-regular text-[16px] leading-[24px]">
              {data.gain}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-black roboto-medium text-[16px] leading-[24px]">
              Profit Factor
            </p>
            <div className="flex flex-col justify-center items-center">
              <div className="rounded-full bg-[#D8FFB6] px-[15px] py-[3px] flex flex-row items-center gap-2">
                <div className="w-3 h-3 bg-[#1DAD32] rounded-full"></div>
                <p className="text-black text-base leading-[150%]">{data.profitfactor}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-black roboto-medium text-[16px] leading-[24px]">
              # Trades
            </p>
            <p className="text-black roboto-regular text-[16px] leading-[24px]">
              {data.trades}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-black roboto-medium text-[16px] leading-[24px]">
              % Profitable
            </p>
            <p className="text-black roboto-regular text-[16px] leading-[24px]">
              {data.profitable}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
