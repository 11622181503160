import Header from "../components/Header";
import "../assets/css/styles.css";
import FooterContents from "../components/FooterContents";
import tele_icon from "../assets/img/telegram_icon.png";
import { useEffect, useState } from "react";
import axiosInstance from "../utils/axios";
import { Link, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import logo from "../assets/signup/logo.png";
import hero from "../assets/signup/hero.png";
import { Button } from "../components/button";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function SignUp() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get("ref");
  const [referralUser, setReferralUser] = useState(null);

  const agreements = [
    {
      id: 1,
      name: "Terms of Service",
      url: "/assets/compliances/Agreement - TERMS OF SERVICE.pdf",
    },
    {
      id: 2,
      name: "Risk Disclosure",
      url: "/assets/compliances/Agreement - RISK DISCLOSURE AGREEMENT.pdf",
    },
    {
      id: 3,
      name: "Privacy Policy",
      url: "/assets/compliances/Agreement - PRIVACY POLICY.pdf",
    },
    {
      id: 4,
      name: "User Agreement",
      url: "/assets/compliances/Agreement - CLIENT AGREEMENT.pdf",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    membership: "signal",
    agreements: [],
    promotion_code: "",
  });

  const [errors, setErrors] = useState({});
  const [waiting, setWaiting] = useState(false);
  const [applyingPromotionCode, setApplyingPromotionCode] = useState(false);
  const [discount, setDiscount] = useState({});

  const handleChange = (e) => {
    // if (e.target.name !== "phone") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    // } else {
      // let num = e.target.value.replace(/\D/g, "");
    //   setFormData({
    //     ...formData,
    //     [e.target.name]:
    //       "(" +
    //       num.substring(0, 3) +
    //       ") " +
    //       num.substring(3, 6) +
    //       "-" +
    //       num.substring(6, 10),
    //   });
    // }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleAgreementChange = (agreement) => {
    setFormData((prevData) => ({
      ...prevData,
      agreements: prevData.agreements.includes(agreement)
        ? prevData.agreements.filter((a) => a !== agreement)
        : [...prevData.agreements, agreement],
    }));
  };

  const applyPromotionCode = async (e) => {
    e.preventDefault();
    try {
      setApplyingPromotionCode(true);
      const response = await axiosInstance.post(
        "/calculate-discount",
        {
          promotion_code: formData.promotion_code,
          membership: formData.membership,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = response.data;
      setDiscount(res);
    } catch (error) {
      setDiscount({});
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        console.error("Error:", error.message);
      }
    } finally {
      setApplyingPromotionCode(false);
    }
  };

  const validateForm = () => {
    const tempErrors = {};

    if (!formData.name.trim()) tempErrors.name = "Name is required.";
    if (formData.username.length < 5)
      tempErrors.username = "Username must be at least 5 characters.";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      tempErrors.email = "Email address is invalid.";
    if (formData.password.length < 6)
      tempErrors.password = "Password must be at least 6 characters.";
    if (formData.confirmPassword !== formData.password)
      tempErrors.confirmPassword = "Passwords do not match.";
    if (!/^\+?[1-9]\d{1,14}$/.test(formData.phone))
      tempErrors.phone = "Please enter a valid phone number.";
    if (formData.agreements.length !== agreements.length)
      tempErrors.agreements = "You must agree to all terms.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    // Handle form submission and Stripe payment here
    try {
      setWaiting(true);
      const response = await axiosInstance.post(
        "/signup",
        {
          ...formData,
          agreement: agreements.length == 4,
          referral: referralCode,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setWaiting(false);
      const res = response.data;
      localStorage.setItem("email", formData.email);
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: res.sessionId,
      });
      if (result.error) {
        toast.error(result.error.message);
      }
    } catch (error) {
      setWaiting(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const hasErrors = () => {
    for (const key in errors) if (errors[key] != "") return true;
    return false;
  };

  useEffect(() => {
    if (!referralCode) return;
    const fetchReferralUser = async () => {
      try {
        const response = await axiosInstance.post("/user-by-referralcode", {
          referral_code: referralCode,
        });
        toast.success(
          `You were referred by ${
            response.data.name != ""
              ? response.data.name
              : response.data.username
          }`
        );
        setReferralUser(response.data);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.msg);
        } else {
          console.error("Error:", error.message);
        }
      }
    };

    fetchReferralUser();
  }, [referralCode]);

  return (
    <div className="flex flex-row bg-[#F7F7FD]">
      <div className="grow my-auto overflow-y-auto max-h-[100vh]">
        <div className="max-w-[630px] mx-auto flex flex-col items-center gap-[32px] px-5">
          <img src={logo} />
          <p className="md:text-[48px] text-[32px] leading-[120%] max-md:text-center text-[#1D2130] roboto-medium text-center">
            Sign up to become a member
          </p>
          <form
            onSubmit={handleSubmit}
            className="p-10 rounded-[20px] shadow-[0px_80.6px_105.7px_0px_#3333331a] bg-white md:min-w-[500px]"
          >
            <div className="flex flex-col gap-[10px]">
              <div>
                <input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                  placeholder="Name"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div>
                <input
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                  className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                  placeholder="Username"
                />
                {errors.username && (
                  <p className="text-red-500 text-sm">{errors.username}</p>
                )}
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                  placeholder="Email (contactus@nucfdc.in)"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                  placeholder="Password"
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>
              <div>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                  className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                  placeholder="Confirm Password"
                />
                {errors.confirmPassword && (
                  <p className="text-red-500 text-sm">
                    {errors.confirmPassword}
                  </p>
                )}
              </div>
              <div>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                  placeholder="Phone"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>
              <select
                name="membership"
                value={formData.membership}
                onChange={handleChange}
                placeholder="Membership Type"
                className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
              >
                <option value="signal">Signal Only - Monthly</option>
                <option value="signal-annual">Signal Only - Annual</option>
                <option value="auto">Auto Trader Enabled - Monthly</option>
                <option value="auto-annual">
                  Auto Trader Enabled - Annual
                </option>
              </select>
              <div>
                <div className="flex flex-row gap-2">
                  <input
                    id="promotion_code"
                    name="promotion_code"
                    value={formData.promotion_code}
                    onChange={handleChange}
                    className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                    placeholder="Promotional Code"
                  />
                  <Button
                    color="primary"
                    onClick={applyPromotionCode}
                    className="bg-blue-500 cursor-pointer text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                    disabled={
                      formData.promotion_code == "" || applyingPromotionCode
                    }
                  >
                    {applyingPromotionCode ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                        ></path>
                      </svg>
                    ) : (
                      "Apply"
                    )}
                  </Button>
                </div>
                {errors.promotion_code && (
                  <p className="text-red-500 text-sm">
                    {errors.promotion_code}
                  </p>
                )}
              </div>
              <div>
                {Object.keys(discount).length > 0 && (
                  <div className="bg-gray-100 p-4 rounded mt-4 text-gray-800">
                    <h3 className="font-bold text-lg mb-2">
                      Discount Information
                    </h3>
                    <ul className="space-y-1">
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">
                          Discount Details:
                        </span>
                        <span className="w-1/2 text-left">
                          {discount.discount_type === "amount" && "$"}
                          {discount.discount_value}
                          {discount.discount_type === "percent" && "%"}
                        </span>
                      </li>
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">
                          Original Price:
                        </span>
                        <span className="w-1/2 text-left">
                          ${discount.original_price}
                        </span>
                      </li>
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">
                          Discount Amount:
                        </span>
                        <span className="w-1/2 text-left">
                          ${discount.discount_amount}
                        </span>
                      </li>
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">
                          Discounted Price:
                        </span>
                        <span className="w-1/2 text-left">
                          ${discount.discounted_price}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Agreements</label>
                {agreements.map((agreement) => (
                  <div key={agreement.id}>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        onChange={() => handleAgreementChange(agreement.name)}
                        checked={formData.agreements.includes(agreement.name)}
                        className="form-checkbox"
                      />
                      <Link to={agreement.url} target="_blank">
                        <span className="ml-2 text-blue-400 underline">
                          {agreement.name}
                        </span>
                      </Link>
                    </label>
                  </div>
                ))}
                {errors.agreements && (
                  <p className="text-red-500 text-sm">{errors.agreements}</p>
                )}
              </div>
              <div>
                <Button
                  type="submit"
                  className="robot-regular text-[14px] py-[10px] px-[60px] rounded-full  h-[50px] shadow-[0px_80.6px_105.7px_0px_#3333331a]"
                  color="danger"
                  disabled={waiting}
                >
                  {waiting ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <img src={hero} className="h-[100vh] max-lg:hidden" />
    </div>
  );
}

export default SignUp;
