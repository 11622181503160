import { useState } from "react";
import { FaqItem } from "./faqitem";
import { Accordion } from "./accordion";
import { marketPeriod } from "../utils/dummydata";

export const FAQ = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div
      id="faqs"
      className="md:px-[100px] px-[20px] flex flex-col bg-white text-[#1D2130] relative pb-10"
    >
      <p className="text-[48px] leading-[120%] max-w-[1090px] mx-auto roboto-medium text-center mb-10">
        AI Systemics - Frequently Asked Questions (FAQ)
      </p>
      <p className="text-black roboto-bold my-2">General Questions</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={0}
        isfirst
        onClick={setSelectedIndex}
        title={"What is AI Systemics"}
      >
        <p>
          AI Systemics is an automated trading platform that leverages advanced
          algorithms to trade futures contracts, such as the S&P 500. Our system
          provides users with trading signals and automated trading capabilities
          to optimize their investments.
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={1}
        onClick={setSelectedIndex}
        title={"What markets does AI Systemics trade in?"}
      >
        <p>
          Currently, AI Systemics trades in S&P futures contracts, including
          micro, mini, and full contracts. In the future, we plan to expand to
          other markets and brokers
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={2}
        onClick={setSelectedIndex}
        title={" Who is AI Systemics designed for?"}
      >
        <p>
          Our platform is designed for experienced traders, high-frequency
          traders, financial advisors, and tech-savvy investors looking to
          leverage algorithm-driven trading
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={3}
        onClick={setSelectedIndex}
        title={"Do I need to have a broker account to use AI Systemics?"}
      >
        <p>
          Yes, you must have a TradeStation brokerage account with a minimum of
          $10,000 to connect with our platform. Support for other brokers will
          be added in the future
        </p>
      </FaqItem>
      <p className="text-black roboto-bold my-2">Platform Features</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={4}
        onClick={setSelectedIndex}
        title={"What are the main features of AI Systemics?"}
      >
        <ul style={{ listStyleType: "circle" }}>
          <li>Automated trading via API integration.</li>
          <li>Dynamic risk management with adjustable risk levels.</li>
          <li>Real-time trade execution and monitoring.</li>
          <li>
            Comprehensive dashboard to track trades, balances, and performance.
          </li>
          <li>Referral program for earning additional income.</li>
        </ul>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={5}
        onClick={setSelectedIndex}
        title={"How does the trading algorithm work?"}
      >
        <p>
          The algorithm analyzes market data to identify trading opportunities
          in real time. It executes trades automatically based on predefined
          rules and risk parameters, ensuring precision and consistency. We have
          developed our own technical indicators and have back-tested heavily to
          ensure maximum profitability. Our proprietary Predictability 2.0
          indicator significantly reduces potential losses by managing optimum
          targets and minimizing losses with dynamic stop-loss management.
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={6}
        onClick={setSelectedIndex}
        title={"Can I customize my trading settings?"}
      >
        <p>
          Yes, you can adjust your risk tolerance (low, medium, high) and enable
          or disable auto-trading via your account settings.
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={7}
        onClick={setSelectedIndex}
        title={"What is the platform’s historical performance?"}
      >
        <p>
          Based on 20 years of backtesting, the algorithm demonstrates an
          average annual return of 100% and a win rate of 79%, significantly
          outperforming industry averages
        </p>
      </FaqItem>
      <p className="text-black roboto-bold my-2">Subscription and Pricing</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={8}
        onClick={setSelectedIndex}
        title={"What are the subscription options?"}
      >
        <ol style={{ listStyle: "decimal" }} className="mb-2">
          <li>
            <span className="roboto-bold">Signal Only - Monthly:</span>{" "}
            $79.99/month.
          </li>
          <li>
            <span className="roboto-bold">Signal Only - Annual:</span>{" "}
            $59.99/month (25% discount).
          </li>
          <li>
            <span className="roboto-bold">Signal + Auto Trading</span> -
            Monthly: $119/month.
          </li>
          <li>
            <span className="roboto-bold">Signal + Auto Trading</span> - Annual:
            $89.25/month (25% discount).
          </li>
        </ol>
        Note: Monthly subscription fees are waived for premium accounts with
        balances over $100,000. If you plan to become a premium subscriber,
        please contact us directly. We will provide you with a coupon to ensure
        your subscription reflects the waiver properly.
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={9}
        onClick={setSelectedIndex}
        title={"What’s included in each subscription?"}
      >
        <ul style={{ listStyleType: "circle" }}>
          <li>
            Signal Only: Trading signals, alerts (email/SMS), and interactive
            charts.
          </li>
          <li>
            Signal + Auto Trading: Includes all Signal Only features plus
            automated trading, priority support, and profit monitoring.
          </li>
        </ul>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={10}
        onClick={setSelectedIndex}
        title={"Are there any additional fees?"}
      >
        <p>
          AI Systemics charges a 30% profit share on trading gains. If there are
          no profits, no fees are charged
        </p>
      </FaqItem>
      <p className="text-black roboto-bold my-2">Getting Started</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={11}
        onClick={setSelectedIndex}
        title={"How do I sign up?"}
      >
        <ol style={{ listStyle: "decimal" }}>
          <li>Visit our website and click "Sign Up."</li>
          <li>Complete the registration form and agree to the terms.</li>
          <li>Connect your TradeStation account using API keys.</li>
          <li>Choose your subscription plan and pay the fee.</li>
        </ol>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={12}
        onClick={setSelectedIndex}
        title={"How do I enable auto-trading?"}
      >
        <p>
          Once registered, log in to your dashboard, connect your broker
          account, and toggle the Auto-Trading feature to "Enable."
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={13}
        onClick={setSelectedIndex}
        title={"Can I try the platform before subscribing?"}
      >
        <p>
          At this time, we do not offer free trials. However, you can start with
          the Signal Only plan to evaluate the platform’s signals.
        </p>
      </FaqItem>
      <p className="text-black roboto-bold my-2">Referral Program</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={14}
        onClick={setSelectedIndex}
        title={"How does the referral program work?"}
      >
        <p>
          Earn income by referring others to AI Systemics. Each client receives
          a unique referral link that can be copied and pasted into any email or
          text message, making it easy to share. When someone signs up using
          your referral link, you earn up to 20% of AI Systemics' profits
          generated by that referred client, recurring every month as long as
          the client remains active
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={15}
        onClick={setSelectedIndex}
        title={"What are the referral options?"}
      >
        <ol style={{ listStyle: "decimal" }}>
          <li>
            <p className="roboto-bold">One-Time Payment</p>
            <ul style={{ listStyleType: "circle" }} className="ml-5">
              <li>TBD amount to be defined soon.</li>
            </ul>
          </li>
          <li>
            <p className="roboto-bold">Recurring Revenue</p>
            <ul style={{ listStyleType: "circle" }} className="ml-5">
              <li>
                Earn up to 20% of AI Systemics' profits from referred clients
                every month.
              </li>
            </ul>
          </li>
        </ol>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={16}
        onClick={setSelectedIndex}
        title={"How do I track my referrals?"}
      >
        <p>
          Log in to your dashboard to view referral details, including earnings
          and active clients.
        </p>
      </FaqItem>
      <p className="text-black roboto-bold my-2">Technical Questions</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={17}
        onClick={setSelectedIndex}
        title={"How is my data secured?"}
      >
        <p>
          We use SSL encryption and secure API integration to protect your data.
          All trading activity is executed through your brokerage account,
          ensuring control and transparency.
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={18}
        onClick={setSelectedIndex}
        title={"What happens if there are issues with trade execution?"}
      >
        <p>
          While the bot executes trades based on market conditions, external
          factors such as broker restrictions, market volatility, or margin
          changes may affect trade execution. Clients are responsible for
          monitoring their accounts and consulting with their broker as needed.
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={19}
        onClick={setSelectedIndex}
        title={"What kind of support is available?"}
      >
        <p>
          We offer email support for all users. Priority support is available
          for Signal + Auto Trading subscribers.
        </p>
      </FaqItem>
      {/* Table */}
      <p className="text-black roboto-bold my-2">Past Performance</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={20}
        onClick={setSelectedIndex}
        title={"What are the past performance results?"}
      >
        <p>
          Below are the results from a Mark-To-Market Rolling Period Analysis of
          10-year simulations starting with an initial $10,000 investment.
        </p>
        <Accordion />
        <div className="rounded-t-[10px] overflow-hidden flex flex-col mt-9 border border-[#79797933] max-md:hidden">
          <table>
            <thead className="text-white roboto-regular">
              <tr className="bg-[#182A3E]">
                <th className="text-left text-[16px] leading-[150%] font-normal py-[13px] pl-[40px]">
                  Period
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal">
                  Net Profit
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal">
                  %Gain
                </th>
                <th className="text-center text-[16px] leading-[150%] font-normal">
                  Profit Factor
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal">
                  # Trades
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal pr-16">
                  % Profitable
                </th>
              </tr>
            </thead>
            <tbody className="roboto-regular">
              {marketPeriod.map((item, index) => (
                <tr
                  className={`text-base ${
                    index + 1 !== marketPeriod.length
                      ? "border-b border-[#79797933]"
                      : ""
                  }`}
                  key={index}
                >
                  <td className="pl-10 py-5">{item.period}</td>
                  <td className="text-right">{item.netprofit}</td>
                  <td className="text-right">{item.gain}</td>
                  <td className="">
                    <div className="flex flex-col justify-center items-center">
                      <div className="rounded-full bg-[#D8FFB6] px-[15px] py-[3px] flex flex-row items-center gap-2">
                        <div className="w-3 h-3 bg-[#1DAD32] rounded-full"></div>
                        <p className="text-black text-base leading-[150%]">
                          {item.profitfactor}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-right">{item.trades}</td>
                  <td className="text-right pr-16">{item.profitable}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={21}
        onClick={setSelectedIndex}
        title={"Does past performance guarantee future success?"}
      >
        <p>
          No, past performance does not guarantee future success. While our
          algorithm is based on extensive backtesting and historical data, all
          trading involves risk, and results may vary.
        </p>
      </FaqItem>
      <p className="text-black roboto-bold my-2">Compilance and Legal</p>
      <FaqItem
        selectedItem={selectedIndex}
        index={22}
        onClick={setSelectedIndex}
        title={"Is AI Systemics a financial advisor?"}
      >
        <p>
          No, AI Systemics is a signal provider offering automated trading
          services. We do not provide personalized financial advice or manage
          client accounts.
        </p>
      </FaqItem>
      <FaqItem
        selectedItem={selectedIndex}
        index={23}
        onClick={setSelectedIndex}
        title={"Does AI Systemics guarantee profits?"}
      >
        <p>
          No. While our algorithm is based on extensive backtesting and
          historical data, all trading involves risk, and past performance is
          not indicative of future results
        </p>
      </FaqItem>
      <p className="roboto-regular mt-5">
        For additional questions, please contact us via our{" "}
        <span className="roboto-bold">Contact US</span> page or email us at{" "}
        <span className="roboto-bold">support@aisystemics.com</span>
      </p>
    </div>
  );
};
