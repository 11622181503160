export const Tradier = () => {
  return (
    <div>
      <div className="max-md:hidden"><svg
      width="77"
      height="37"
      viewBox="0 0 77 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_72_885)">
        <mask
          id="mask0_72_885"
        //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-58"
          y="-98"
          width="203"
          height="265"
        >
          <path
            d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_72_885)">
          <path
            d="M6.10134 35.636H1.98964V27.3612H0V23.9423H1.98964V20.1926H6.10134V23.9423H8.42534V27.3612H6.10134V35.636Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask1_72_885"
        //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-58"
          y="-98"
          width="203"
          height="265"
        >
          <path
            d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_72_885)">
          <mask
            id="mask2_72_885"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="-58"
            y="-98"
            width="203"
            height="265"
          >
            <path
              d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_72_885)">
            <path
              d="M13.8447 25.8474H13.8911C14.6823 24.4103 16.0363 23.772 17.6386 23.772H18.4298V27.769C17.8959 27.4835 17.2952 27.3495 16.6918 27.3813C14.4108 27.3813 13.8447 28.9086 13.8447 30.9138V35.6327H9.73633V23.9357H13.8447V25.8474Z"
              fill="currentColor"
            />
            <path
              d="M32.556 35.6327H28.4343V34.336H28.4012C27.6795 35.499 26.256 36.007 24.902 36.007C21.4689 36.007 19.0522 33.1095 19.0522 29.7608C19.0522 26.4121 21.4226 23.5347 24.8556 23.5347C26.1799 23.5347 27.5901 24.036 28.4476 25.062V23.939H32.556V35.6327ZM23.2864 29.7976C23.2864 31.2814 24.2597 32.421 25.9349 32.421C27.61 32.421 28.5833 31.2814 28.5833 29.7976C28.5833 29.0885 28.3043 28.4084 27.8076 27.9071C27.3109 27.4057 26.6373 27.124 25.9349 27.124C25.2325 27.124 24.5588 27.4057 24.0621 27.9071C23.5655 28.4084 23.2864 29.0885 23.2864 29.7976Z"
              fill="currentColor"
            />
            <path
              d="M42.9742 34.3361H42.9278C42.2061 35.4991 40.7826 36.0071 39.4286 36.0071C35.9956 36.0071 33.5789 33.1096 33.5789 29.7609C33.5789 26.4122 35.9492 23.5347 39.3822 23.5347C40.7065 23.5347 42.1168 24.036 42.9742 25.062V16.707H47.0826V35.6261H42.9742V34.3361ZM37.823 29.7976C37.823 31.2815 38.7963 32.4211 40.4714 32.4211C42.1466 32.4211 43.1199 31.2815 43.1199 29.7976C43.1199 29.0886 42.8408 28.4085 42.3441 27.9071C41.8475 27.4057 41.1738 27.124 40.4714 27.124C39.769 27.124 39.0954 27.4057 38.5987 27.9071C38.102 28.4085 37.823 29.0886 37.823 29.7976Z"
              fill="currentColor"
            />
            <path
              d="M53.3297 19.4474C53.3329 19.9108 53.1998 20.3648 52.9472 20.7517C52.6945 21.1386 52.3338 21.441 51.9106 21.6206C51.4875 21.8003 51.021 21.849 50.5704 21.7606C50.1198 21.6722 49.7053 21.4508 49.3796 21.1243C49.0538 20.7978 48.8315 20.381 48.7407 19.9267C48.6499 19.4725 48.6949 19.0013 48.8698 18.5728C49.0447 18.1444 49.3417 17.778 49.7231 17.5202C50.1045 17.2624 50.5532 17.1248 51.0123 17.1248C51.624 17.1247 52.211 17.3689 52.6451 17.804C53.0792 18.2391 53.3253 18.8299 53.3297 19.4474ZM53.0814 35.6327H48.9597V23.9357H53.0714L53.0814 35.6327Z"
              fill="currentColor"
            />
            <path
              d="M67.2372 30.4828H58.5901C58.5901 32.1538 59.4707 33.0829 61.1624 33.0829C61.5577 33.1142 61.9535 33.0296 62.3024 32.8393C62.6512 32.649 62.9382 32.3611 63.1289 32.0101H67.0783C66.9353 32.6384 66.6676 33.2309 66.2915 33.7518C65.9155 34.2728 65.4387 34.7114 64.8901 35.0413C63.768 35.706 62.4863 36.0459 61.1856 36.0238C57.3486 36.0238 54.4784 33.8348 54.4784 29.7977C54.4784 25.9009 57.1268 23.5515 60.8942 23.5515C64.9132 23.5515 67.2372 26.0614 67.2372 30.0517V30.4828ZM63.4202 28.0899C63.3071 27.5579 63.0139 27.0823 62.5909 26.745C62.1679 26.4076 61.6417 26.2297 61.1028 26.2418C59.9044 26.2418 58.9112 26.8801 58.6629 28.0899H63.4202Z"
              fill="currentColor"
            />
            <path
              d="M72.4149 25.8474H72.4646C73.2558 24.4103 74.6098 23.772 76.2121 23.772H77.0033V27.769C76.4694 27.4835 75.8687 27.3495 75.2653 27.3813C72.9843 27.3813 72.4182 28.9086 72.4182 30.9138V35.6327H68.3065V23.9357H72.4149V25.8474Z"
              fill="currentColor"
            />
          </g>
        </g>
        <mask
          id="mask3_72_885"
        //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="54"
          y="0"
          width="21"
          height="21"
        >
          <path
            d="M60.0964 0.785645L54.6737 6.22976H64.3736L54.8624 15.8313L59.1264 20.1057L68.6277 10.5075V20.2862L74.047 14.8454V0.785645H60.0964Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_72_885)">
          <mask
            id="mask4_72_885"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="-58"
            y="-98"
            width="203"
            height="265"
          >
            <path
              d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_72_885)">
            <path
              d="M54.6737 19.6747L72.1633 2.67065L63.9962 22.0442L54.6737 19.6747Z"
              fill="currentColor"
            />
            <path
              d="M56.7726 18.1875L54.4784 15.808L63.642 6.45045L72.1633 2.67065L56.7726 18.1875Z"
              fill="currentColor"
            />
            <path
              d="M53.7931 6.74455L55.1305 5.44785L72.1666 2.67065L64.3736 6.23322L53.7931 6.74455Z"
              fill="currentColor"
            />
            <path
              d="M68.6277 10.5076L72.1666 2.67065L69.1772 20.2863L68.7833 20.6707L68.1642 20.2863L68.6277 10.5076Z"
              fill="currentColor"
            />
            <path
              d="M72.1633 2.67058L54.6737 6.23315L59.9176 0.454834L72.1633 2.67058Z"
              fill="currentColor"
            />
            <path
              d="M60.093 0.785657L62.278 0.0236816L74.3582 0.601847L72.256 2.68391L60.093 0.785657Z"
              fill="currentColor"
            />
            <path
              d="M68.6277 20.2863L72.1667 2.67065L74.8681 15.6209L68.6277 20.2863Z"
              fill="currentColor"
            />
            <path
              d="M74.047 14.8454L72.1633 2.67053L74.047 0.785645L75.0038 13.1744L74.047 14.8454Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_72_885">
          <rect
            width="77"
            height="36"
            fill="white"
            transform="translate(0 0.0236816)"
          />
        </clipPath>
      </defs>
    </svg></div>
      <div className="md:hidden">
      <svg
      width="49"
      height="34"
      viewBox="0 0 77 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_72_885)">
        <mask
          id="mask0_72_885"
        //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-58"
          y="-98"
          width="203"
          height="265"
        >
          <path
            d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_72_885)">
          <path
            d="M6.10134 35.636H1.98964V27.3612H0V23.9423H1.98964V20.1926H6.10134V23.9423H8.42534V27.3612H6.10134V35.636Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask1_72_885"
        //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-58"
          y="-98"
          width="203"
          height="265"
        >
          <path
            d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_72_885)">
          <mask
            id="mask2_72_885"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="-58"
            y="-98"
            width="203"
            height="265"
          >
            <path
              d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_72_885)">
            <path
              d="M13.8447 25.8474H13.8911C14.6823 24.4103 16.0363 23.772 17.6386 23.772H18.4298V27.769C17.8959 27.4835 17.2952 27.3495 16.6918 27.3813C14.4108 27.3813 13.8447 28.9086 13.8447 30.9138V35.6327H9.73633V23.9357H13.8447V25.8474Z"
              fill="currentColor"
            />
            <path
              d="M32.556 35.6327H28.4343V34.336H28.4012C27.6795 35.499 26.256 36.007 24.902 36.007C21.4689 36.007 19.0522 33.1095 19.0522 29.7608C19.0522 26.4121 21.4226 23.5347 24.8556 23.5347C26.1799 23.5347 27.5901 24.036 28.4476 25.062V23.939H32.556V35.6327ZM23.2864 29.7976C23.2864 31.2814 24.2597 32.421 25.9349 32.421C27.61 32.421 28.5833 31.2814 28.5833 29.7976C28.5833 29.0885 28.3043 28.4084 27.8076 27.9071C27.3109 27.4057 26.6373 27.124 25.9349 27.124C25.2325 27.124 24.5588 27.4057 24.0621 27.9071C23.5655 28.4084 23.2864 29.0885 23.2864 29.7976Z"
              fill="currentColor"
            />
            <path
              d="M42.9742 34.3361H42.9278C42.2061 35.4991 40.7826 36.0071 39.4286 36.0071C35.9956 36.0071 33.5789 33.1096 33.5789 29.7609C33.5789 26.4122 35.9492 23.5347 39.3822 23.5347C40.7065 23.5347 42.1168 24.036 42.9742 25.062V16.707H47.0826V35.6261H42.9742V34.3361ZM37.823 29.7976C37.823 31.2815 38.7963 32.4211 40.4714 32.4211C42.1466 32.4211 43.1199 31.2815 43.1199 29.7976C43.1199 29.0886 42.8408 28.4085 42.3441 27.9071C41.8475 27.4057 41.1738 27.124 40.4714 27.124C39.769 27.124 39.0954 27.4057 38.5987 27.9071C38.102 28.4085 37.823 29.0886 37.823 29.7976Z"
              fill="currentColor"
            />
            <path
              d="M53.3297 19.4474C53.3329 19.9108 53.1998 20.3648 52.9472 20.7517C52.6945 21.1386 52.3338 21.441 51.9106 21.6206C51.4875 21.8003 51.021 21.849 50.5704 21.7606C50.1198 21.6722 49.7053 21.4508 49.3796 21.1243C49.0538 20.7978 48.8315 20.381 48.7407 19.9267C48.6499 19.4725 48.6949 19.0013 48.8698 18.5728C49.0447 18.1444 49.3417 17.778 49.7231 17.5202C50.1045 17.2624 50.5532 17.1248 51.0123 17.1248C51.624 17.1247 52.211 17.3689 52.6451 17.804C53.0792 18.2391 53.3253 18.8299 53.3297 19.4474ZM53.0814 35.6327H48.9597V23.9357H53.0714L53.0814 35.6327Z"
              fill="currentColor"
            />
            <path
              d="M67.2372 30.4828H58.5901C58.5901 32.1538 59.4707 33.0829 61.1624 33.0829C61.5577 33.1142 61.9535 33.0296 62.3024 32.8393C62.6512 32.649 62.9382 32.3611 63.1289 32.0101H67.0783C66.9353 32.6384 66.6676 33.2309 66.2915 33.7518C65.9155 34.2728 65.4387 34.7114 64.8901 35.0413C63.768 35.706 62.4863 36.0459 61.1856 36.0238C57.3486 36.0238 54.4784 33.8348 54.4784 29.7977C54.4784 25.9009 57.1268 23.5515 60.8942 23.5515C64.9132 23.5515 67.2372 26.0614 67.2372 30.0517V30.4828ZM63.4202 28.0899C63.3071 27.5579 63.0139 27.0823 62.5909 26.745C62.1679 26.4076 61.6417 26.2297 61.1028 26.2418C59.9044 26.2418 58.9112 26.8801 58.6629 28.0899H63.4202Z"
              fill="currentColor"
            />
            <path
              d="M72.4149 25.8474H72.4646C73.2558 24.4103 74.6098 23.772 76.2121 23.772H77.0033V27.769C76.4694 27.4835 75.8687 27.3495 75.2653 27.3813C72.9843 27.3813 72.4182 28.9086 72.4182 30.9138V35.6327H68.3065V23.9357H72.4149V25.8474Z"
              fill="currentColor"
            />
          </g>
        </g>
        <mask
          id="mask3_72_885"
        //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="54"
          y="0"
          width="21"
          height="21"
        >
          <path
            d="M60.0964 0.785645L54.6737 6.22976H64.3736L54.8624 15.8313L59.1264 20.1057L68.6277 10.5075V20.2862L74.047 14.8454V0.785645H60.0964Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_72_885)">
          <mask
            id="mask4_72_885"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="-58"
            y="-98"
            width="203"
            height="265"
          >
            <path
              d="M144.966 -97.8066H-57.6399V166.88H144.966V-97.8066Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_72_885)">
            <path
              d="M54.6737 19.6747L72.1633 2.67065L63.9962 22.0442L54.6737 19.6747Z"
              fill="currentColor"
            />
            <path
              d="M56.7726 18.1875L54.4784 15.808L63.642 6.45045L72.1633 2.67065L56.7726 18.1875Z"
              fill="currentColor"
            />
            <path
              d="M53.7931 6.74455L55.1305 5.44785L72.1666 2.67065L64.3736 6.23322L53.7931 6.74455Z"
              fill="currentColor"
            />
            <path
              d="M68.6277 10.5076L72.1666 2.67065L69.1772 20.2863L68.7833 20.6707L68.1642 20.2863L68.6277 10.5076Z"
              fill="currentColor"
            />
            <path
              d="M72.1633 2.67058L54.6737 6.23315L59.9176 0.454834L72.1633 2.67058Z"
              fill="currentColor"
            />
            <path
              d="M60.093 0.785657L62.278 0.0236816L74.3582 0.601847L72.256 2.68391L60.093 0.785657Z"
              fill="currentColor"
            />
            <path
              d="M68.6277 20.2863L72.1667 2.67065L74.8681 15.6209L68.6277 20.2863Z"
              fill="currentColor"
            />
            <path
              d="M74.047 14.8454L72.1633 2.67053L74.047 0.785645L75.0038 13.1744L74.047 14.8454Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_72_885">
          <rect
            width="77"
            height="36"
            fill="white"
            transform="translate(0 0.0236816)"
          />
        </clipPath>
      </defs>
    </svg>
      </div>
    </div>
  );
};
