import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { Button } from "./button";
import { slide as Menu } from "react-burger-menu";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/authSlice";

export default function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, loading, user } = useSelector((state) => state.auth);
  const isSignupPath = location.pathname.includes("/signup");
  const isLoginPath = location.pathname.includes("/login");
  const history = useNavigate();

  const [bgColor, setBgColor] = useState("bg-transparent");

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setBgColor("bg-white shadow-md");
    } else {
      setBgColor("bg-transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isToggled, setIsToggled] = useState(false);

  const showmenu = () => {
    setIsToggled(!isToggled);
  };

  const handleSignOut = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="fixed left-0 top-0 z-20 w-full xl:px-[100px] px-[20px] lg:py-[30px] py-3 flex flex-row justify-between bg-white">
        <div className="flex flex-col justify-center">
          <Link to="/"><img src={logo} className="max-md:h-[9px] h-[38px]" /></Link>
        </div>
        <Menu right>
          <Link id="home" className="menu-item" to={`${process.env.REACT_APP_BACKEND_URL}dashboard`}>
            Trading Bot 
          </Link>
          <Link id="about" className="menu-item" to="/features">
            Features
          </Link>
          <Link id="contact" className="menu-item" to="/plan">
            Plans
          </Link>
          <Link className="menu-item" to="/referrals">
            Referrals
          </Link>
          <Link className="menu-item" to="#contact">
            Support / Contact
          </Link>
          <a href="#faqs" className="transition-all">
              FAQs
            </a>
          <Link className="menu-item" to="/login">
            Sign In
          </Link>
          <Link className="menu-item" to="/signup">
            Sign Up
          </Link>
        </Menu>
        <div className="flex flex-col justify-center max-lg:hidden">
          <div className="flex flex-row items-center gap-7 roboto-regular text-[20px] leading-[24px] text-[#0A142F]">
            <Link to={`${process.env.REACT_APP_BACKEND_URL}dashboard`} className="transition-all">
              Trading Bot
            </Link>
            <Link to="/features" className="transition-all">
              Features
            </Link>
            <Link to="/plan" className="transition-all">
              Plans
            </Link>
            <a href="/referrals" className="transition-all">
              Referrals
            </a>
            <a href="#supportcontacts" className="transition-all">
              Support / Contact
            </a>
            <a href="#faqs" className="transition-all">
              FAQs
            </a>
          </div>
        </div>
        <div className="flex flex-row items-center gap-5 max-lg:hidden">
          {isAuthenticated && (
            <p className="text-base text-gray-700 font-semibold text-center">
              {user}
            </p>
          )}
          {isAuthenticated && (
            <Button
              color="primary"
              className="px-5 py-[14px] rounded-[10px] moulpali-regular"
              disabled={loading}
              onClick={handleSignOut}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                  ></path>
                </svg>
              ) : (
                "Sign Out"
              )}
            </Button>
          )}
          {!isAuthenticated && (
            <Button
              color="primary"
              className="px-5 py-[14px] rounded-[10px] moulpali-regular"
              onClick={() => history("/login")}
            >
              Sign In
            </Button>
          )}
          {!isAuthenticated && (
            <Button
              color="danger"
              className="px-5 py-[14px] rounded-[10px] moulpali-regular"
              onClick={() => history("/signup")}
            >
              Sign Up
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
