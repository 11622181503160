export const marketPeriod = [
    {
      period: "1/1/2024 - Today",
      netprofit: "$19,233.75",
      gain: "14.44%",
      profitfactor: "1.34",
      trades: "274",
      profitable: "79.93%",
    },
    {
      period: "1/1/2023 - 1/1/2024",
      netprofit: "$36,310.00",
      gain: "31.28%",
      profitfactor: "1.31",
      trades: "602",
      profitable: "80.90%",
    },
    {
      period: "1/1/2022 - 1/1/2023",
      netprofit: "$63,026.25",
      gain: "70.53%",
      profitfactor: "1.31",
      trades: "1062",
      profitable: "81.26%",
    },
    {
      period: "1/1/2021 - 1/1/2022",
      netprofit: "$67,003.75",
      gain: "78.47%",
      profitfactor: "1.26",
      trades: "1293",
      profitable: "81.13%",
    },
    {
      period: "1/1/2020 - 1/1/2021",
      netprofit: "$90,413.75",
      gain: "145.88%",
      profitfactor: "1.26",
      trades: "1959",
      profitable: "81.32%",
    },
    {
      period: "1/1/2019 - 1/1/2020",
      netprofit: "$110,195.00",
      gain: "261.16%",
      profitfactor: "1.29",
      trades: "2237",
      profitable: "81.49%",
    },
    {
      period: "1/1/2018 - 1/1/2019",
      netprofit: "$118,743.75",
      gain: "352.92%",
      profitfactor: "1.29",
      trades: "2482",
      profitable: "81.55%",
    },
    {
      period: "1/1/2017 - 1/1/2018",
      netprofit: "$125,631.25",
      gain: "469.50%",
      profitfactor: "1.3",
      trades: "2601",
      profitable: "81.66%",
    },
    {
      period: "1/1/2016 - 1/1/2017",
      netprofit: "$130,197.50",
      gain: "586.67%",
      profitfactor: "1.29",
      trades: "2828",
      profitable: "81.58%",
    },
    {
      period: "1/1/2015 - 1/1/2016",
      netprofit: "$137,390.00",
      gain: "915.93%",
      profitfactor: "1.3",
      trades: "2970",
      profitable: "81.58%",
    },
    {
      period: "1/1/2014 - 1/1/2015",
      netprofit: "$137,390.00",
      gain: "915.93%",
      profitfactor: "1.3",
      trades: "2970",
      profitable: "81.58%",
    },
];
