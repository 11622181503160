import Hero1 from "../assets/hero1.png";
import Hero2 from "../assets/hero2.png";
import { Button } from "../components/button";
import { CircleArrowRight } from "../components/icons/circlearrowright";
import Net from "../assets/net.png";
import { Tradestation } from "../components/icons/tradestation";
import { IBKR } from "../components/icons/ibkr";
import { Webull } from "../components/icons/webull";
import { Tradier } from "../components/icons/tradier";
import { Robinhood } from "../components/icons/robinhood";
import { Divider } from "../components/icons/divider";
import { TradeStatus } from "../components/tradestatus";
import { AngleLeft } from "../components/icons/angleleft";
import { AngleRight } from "../components/icons/angleright";
import { Input } from "../components/input";
import { useState } from "react";
import { Magnifier } from "../components/icons/magnifier";
import graph from "../assets/graph.png";
import risk from "../assets/risk.png";
import automated from "../assets/automate.png";
import { marketPeriod } from "../utils/dummydata";
import chart from "../assets/g8.png";
import accountimg from "../assets/account.png";
import newuserimg from "../assets/newuser.png";
import { Footer } from "../components/footer";
import { GetStarted } from "../components/getstarted";
import { Contact } from "../components/contact";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Accordion } from "../components/accordion";
import Header from "../components/Header";
import { FAQ } from "../components/faq";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 770 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function Home() {
  const [searchText, setSearchText] = useState("");

  return (
    <div className="text-xl">
      <Header />
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlaySpeed={3000}
        autoPlay={true}
        arrows={false}
        transitionDuration={500}
      >
        <div className="relative">
          <img
            src={Hero1}
            className="md:h-[100vh] h-[550px] w-full object-cover disable-drag"
          />
          <img src={Net} className="absolute left-0 bottom-20 disable-drag" />
          <div className="absolute lg:left-0 left-[50%] max-lg:top-[90px] max-md:top-[70px] max-lg:-translate-x-[50%] lg:bottom-[190px] md:w-[440px] w-[323px] lg:mx-[201px] ">
            <p className="roboto-medium md:text-[51px] text-[32px] max-lg:text-center md:leading-[70.89px] leading-[44.48px] text-white">
              TRADE SMARTER. GROW FASTER
            </p>
            <p className="moulpali-regular text-[16px] leading-[26px] text-white mb-4 max-lg:text-center">
              Experience the Power of Our Advanced Algorithm for S&P Futures
              Trading. Gain Exposure to America's Most Dynamic Market the Right
              Way—With Precision, Reliability, and Results.”
            </p>
            <Button
              color="danger"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_BACKEND_URL}dashboard`)
              }
              className="flex flex-row rounded-[10px] py-[10px] pr-[10px] pl-[25px] roboto-regular items-center gap-[10px] max-lg:mx-auto"
            >
              Get Started
              <CircleArrowRight />
            </Button>
          </div>
        </div>
        <div className="relative">
          <img
            src={Hero2}
            className="md:h-[100vh] h-[550px] w-full object-cover disable-drag"
          />
          <div className="absolute lg:left-0 left-[50%] max-lg:top-[90px] max-md:top-[70px] max-lg:-translate-x-[50%] lg:bottom-[190px] md:w-[440px] w-[323px] lg:mx-[201px]">
            <p className="roboto-medium md:text-[51px] text-[32px] max-lg:text-center md:leading-[70.89px] leading-[44.48px] text-white">
              TRANSFORM YOUR TRADING
            </p>
            <p className="moulpali-regular text-[16px] leading-[26px] text-white mb-4 max-lg:text-center">
              Let AI Systemics Boost Your Edge in the Futures Market.
            </p>
            <Button
              color="danger"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_BACKEND_URL}dashboard`)
              }
              className="flex flex-row rounded-[10px] py-[10px] pr-[10px] pl-[25px] roboto-regular items-center gap-[10px] max-lg:mx-auto"
            >
              Get Started
              <CircleArrowRight />
            </Button>
          </div>
        </div>
      </Carousel>

      <div className="lg:px-[100px] md:px-[20px] px-0 flex flex-col bg-[#F7F7FD]">
        <div className="relative ">
          <div className="absolute left-0 w-full md:h-[140px] h-[60px] bg-[#182A3E] max-md:pt-2 -translate-y-[50%] md:flex md:flex-col md:justify-center max-md:overflow-hidden">
            <div className="md:flex md:flex-row grid grid-cols-5 md:justify-around justify-between w-full">
              <a
                className="text-[#2864FA] hover:text-[#2864FA] transition-all  flex flex-col justify-center md:justify-end "
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Our Bot currently uses TradeStation as preferred Broker. Others brokers will be available soon"
                data-tooltip-place="top"
              >
                <Tradestation />
              </a>
              <Tooltip id="my-tooltip" place="top" />
              <div className="text-[#BFBFBF]  transition-all  flex flex-col justify-center ">
                <IBKR />
              </div>
              <div className="text-[#BFBFBF]  transition-all  flex flex-col lg:justify-end justify-center ">
                <Webull />
              </div>
              <div className="text-[#BFBFBF]  transition-all  flex flex-col justify-center ">
                <Tradier />
              </div>
              <div className="text-[#BFBFBF]  transition-all  flex flex-col md:justify-end justify-center ">
                <Robinhood />
              </div>
            </div>
          </div>
        </div>
        <div className="shadow-[0px_7px_10px_0px_#4b4b4b1a] border border-white md:mt-[110px] mt-[50px] mb-[10px] md:mb-[60px] h-[60px] bg-white md:py-4 md:pl-[26px] md:pr-[18px] max-md:py-[11px] max-md:px-[18px] roboto-regular flex flex-row gap-[6px] lg:gap-3 text-black">
          <p className="lg:text-[18px] text-[14px] lg:leading-[27px] leading-[21px] max-md:my-auto">
            Popular
          </p>
          <Divider className="border-[#D9D9D9]" />
          <TradeStatus title="NIFTY" value={0.26} />
          <TradeStatus title="BTC" value={1.03} className="max-md:hidden" />
          <TradeStatus title="INRUSD" value={-1.13} className="max-lg:hidden" />
          <TradeStatus title="ETH" value={1.53} className="max-2xl:hidden" />
          <TradeStatus title="BNB" value={-1.03} className="max-2xl:hidden" />
          <TradeStatus title="HFCL" value={-0.64} className="max-2xl:hidden" />
          <TradeStatus title="ITC" value={-0.37} className="max-2xl:hidden" />
          <div className="flex flex-row gap-1 grow justify-end">
            <button className="text-[#d9d9d9] transition-all hover:text-[#b3b3b3]">
              <AngleLeft />
            </button>
            <button className="text-[#d9d9d9] transition-all hover:text-[#b3b3b3]">
              <AngleRight />
            </button>
          </div>
          <Divider />
          <Input
            placeholder="Search Stock"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            endContent={<Magnifier />}
          />
        </div>
        <div className="py-[40px] max-md:px-5 md:grid md:grid-cols-2 max-md:flex max-md:flex-col gap-[40px]">
          <div className="flex flex-col gap-7 max-md:items-center">
            <p className="text-[#CD2C34] roboto-bold md:text-[24px] md:leading-[28.8px] text-[20px] leading-[24px]">
              Discover More
            </p>
            <p className="text-[#1D2130] roboto-medium md:text-[48px] md:leading-[57.6px] text-[32px] leading-[38.4px] max-md:text-center">
              AI Systemics: Your Gateway to Market Success
            </p>
            <p className="text-[#1D2130] roboto-regular md:text-[16px] md:leading-[24px] text-[14px] leading-[21px] max-md:text-center">
              Our advanced algorithm empowers you to make the most of S&P
              futures by providing
            </p>
          </div>
          <div className="flex flex-col gap-[15px]">
            <div className="flex flex-row md:gap-8 gap-1">
              <div>
                <img src={graph} className="min-w-[72px]" />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#1D2130] roboto-bold text-[22px] leading-[30.8px]">
                  Precision Timing
                </p>
                <p className="text-[#1D2130] roboto-regular text-[18px] leading-[27px]">
                  Real-time trading signals ensure you enter and exit the market
                  at the optimal times.
                </p>
              </div>
            </div>
            <div className="flex flex-row md:gap-8 gap-1">
              <div>
                <img src={risk} className="min-w-[72px]" />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#1D2130] roboto-bold text-[22px] leading-[30.8px]">
                  Dynamic Risk Management
                </p>
                <p className="text-[#1D2130] roboto-regular text-[18px] leading-[27px]">
                  Protect your assets with built-in dynamic stops and targets
                  designed to minimize losses and lock in profits.
                </p>
              </div>
            </div>
            <div className="flex flex-row md:gap-8 gap-1">
              <div>
                <img src={automated} className="min-w-[72px]" />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#1D2130] roboto-bold text-[22px] leading-[30.8px]">
                  Automated Trading
                </p>
                <p className="text-[#1D2130] roboto-regular text-[18px] leading-[27px]">
                  Let the AI Systemics platform handle the complexities of
                  trading for you, executing strategies with speed and precision
                  through API-connected accounts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:px-[100px] px-5 py-[50px] flex flex-col bg-[#F7F7FD] text-[#1D2130]">
        <div className="flex md:flex-row md:justify-between flex-col md:gap-[105px] gap-[17px] items-center">
          <p className="roboto-medium md:text-[48px] md:leading-[120%] text-[32px] leading-[38.4px] max-md:text-center">
            Mark-To-Market Rolling Period
          </p>
          <p className="roboto-regular md:text-base md:leading-[150%] text-[14px] leading-[21px] max-md:text-center">
            Automated, Advanced, and Optimized – Your Gateway to S&P and
            Commodity Trading
          </p>
        </div>
        <Accordion />
        <div className="rounded-t-[10px] overflow-hidden flex flex-col mt-9 border border-[#79797933] max-md:hidden">
          <table>
            <thead className="text-white roboto-regular">
              <tr className="bg-[#182A3E]">
                <th className="text-left text-[16px] leading-[150%] font-normal py-[13px] pl-[40px]">
                  Period
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal">
                  Net Profit
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal">
                  %Gain
                </th>
                <th className="text-center text-[16px] leading-[150%] font-normal">
                  Profit Factor
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal">
                  # Trades
                </th>
                <th className="text-right text-[16px] leading-[150%] font-normal pr-16">
                  % Profitable
                </th>
              </tr>
            </thead>
            <tbody className="roboto-regular">
              {marketPeriod.map((item, index) => (
                <tr
                  className={`text-base ${
                    index + 1 !== marketPeriod.length
                      ? "border-b border-[#79797933]"
                      : ""
                  }`}
                  key={index}
                >
                  <td className="pl-10 py-5">{item.period}</td>
                  <td className="text-right">{item.netprofit}</td>
                  <td className="text-right">{item.gain}</td>
                  <td className="">
                    <div className="flex flex-col justify-center items-center">
                      <div className="rounded-full bg-[#D8FFB6] px-[15px] py-[3px] flex flex-row items-center gap-2">
                        <div className="w-3 h-3 bg-[#1DAD32] rounded-full"></div>
                        <p className="text-black text-base leading-[150%]">
                          {item.profitfactor}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-right">{item.trades}</td>
                  <td className="text-right pr-16">{item.profitable}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="mt-[30px] roboto-regular md:text-[16px] text-[14px] max-md:leading-[21px] text-[#1D2130]">
          <span className="roboto-bold">Disclaimer</span> : Past returns do not
          guarantee future results. There is a risk of loss. The results shown
          are based on a 10-year simulation using the Micro S&P Futures
          contract, with an initial investment of $10,000 and no reinvestment of
          returns
        </p>
        {/* <p className="md:mt-4 mt-2 roboto-regular md:text-[16px] text-[14px] max-md:leading-[21px] text-[#1D2130]">
          (10 yr simulation base on a $10K initial account equity, trading MES
          contract, without annual reinvesting.)
        </p> */}
      </div>
      <div className="md:px-[100px] px-5 py-[35px] flex md:flex-row md:justify-between max-md:flex-col bg-white text-[#1D2130]">
        <div className="flex flex-col justify-center gap-7 max-w-[470px]">
          <p className="roboto-bold md:text-[24px] md:leading-[120%] text-[20px] max-md:leading-[24px] max-md:text-center text-[#CD2C34]">
            Driving Portfolio Growth
          </p>
          <p className="roboto-medium md:text-[48px] md:leading-[120%] text-[32px] leading-[38.4px] max-md:text-center ">
            How AISystemics Helped Boost Client Profits with Strategic Signals
          </p>
        </div>
        <img src={chart} className="max-md:mt-12" />
      </div>
      <GetStarted />
      <Contact />
      <FAQ />
      <div className="pt-[70px] md:pb-2 pb-[50px] flex flex-col bg-[#F7F7FD] text-[#1D2130]">
        <p className="text-[48px] leading-[120%] max-w-[1090px] mx-auto roboto-medium text-center">
          Choose The Guide You Need To Make The Most Of Your Ai Systemics
          Experience.
        </p>
        <div className="mt-12 flex md:flex-row flex-col max-md:items-center max-md:px-5 justify-center md:gap-5 gap-[50px]">
          <div className="max-w-[505px] py-[38px] px-[34px] flex flex-col gap-4 items-center bg-white rounded-[10px]">
            <img src={accountimg} />
            <p className="md:min-w-[436px] text-center text-[22px] roboto-black text-[#0A142F]">
              Already Have an Account?
            </p>
            <p className="text-[16px] roboto-regular max-md:text-center text-[#1D2130]">
              Discover How to Set It Up and Start Using It
            </p>
            <Button
              className="robot-regular text-[14px] py-[10px] px-[60px] rounded-[10px] h-[50px]"
              color="danger"
              onClick={() =>
                window.open(
                  "/assets/compliances/Already Opened Your AI Systemics Account.pdf",
                  "_blank"
                )
              }
            >
              Learn More
            </Button>
          </div>
          <div className="max-w-[505px] py-[38px] px-[34px] flex flex-col gap-4 items-center bg-white rounded-[10px]">
            <img src={newuserimg} />
            <p className="md:min-w-[436px] text-center text-[22px] roboto-black text-[#0A142F]">
              New to AI Systemics?
            </p>
            <p className="text-[16px] roboto-regular max-md:text-center text-[#1D2130]">
              Find Out How to Open an Account with Us
            </p>
            <Button
              className="robot-regular text-[14px] py-[10px] px-[60px] rounded-[10px] h-[50px]"
              color="primary"
              onClick={() =>
                window.open(
                  "/assets/compliances/How to Open an Account with AI Systemics.pdf",
                  "_blank"
                )
              }
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
