import { GetStarted } from "../components/getstarted";
import { Contact } from "../components/contact";
import { Footer } from "../components/footer";
import { useState } from "react";
import crown from "../assets/plan/crown.png";
import { Button } from "../components/button";
import chart from "../assets/plan/chart.png";
import calendar from "../assets/plan/calendar.png";
import { Check } from "../components/icons/check";
import { Tab } from "../components/tab";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { FAQ } from "../components/faq";

export default function Plan() {
  const [selectedTab, setSelectedTab] = useState("monthly");
  const history = useNavigate();
  return (
    <div>
      <Header />
      <div className="relative md:px-[100px] px-5 pb-[92px] pt-[140px] flex flex-col gap-7 items-center bg-[#F7F7FD] text-[#1D2130]">
        <p className="md:text-[24px] text-[20px] leading-[120%] roboto-bold text-[#CD2C34]">
          Subscription Options
        </p>
        <p className="md:text-[48px] text-[32px] max-md:text-center leading-[120%] roboto-medium text-[#1D2130]">
          Affordable Prices, Powerful Solutions
        </p>
        <Tab
          onChange={(value) => {
            setSelectedTab(value);
          }}
          selectedTab={selectedTab}
        />
        <div className="flex md:flex-row flex-col gap-5">
          <div className="w-[295px] py-[48px] rounded-[24px] bg-white border border-[#d8d8d8] flex flex-col gap-[18px]">
            <div className="flex flex-col items-center px-[17px]">
              <img src={crown} />
              <p className="mt-6 text-[25px] leading-[120%] text-[#1D2130] roboto-bold">
                Premium
              </p>
              <p className="text-[16px] leading-[150%] text-[#1D2130] text-center roboto-regular opacity-60">
                (*with month end balance greater or equal to $100K).
              </p>
            </div>
            <div className="w-full border-t border-[#D8D8D8]"></div>
            <div className="flex flex-col items-center">
              <p className="text-[36px] leading-[120%] roboto-bold text-[#1D2130]">
                $0
              </p>
              <p className="text-[12px] leading-[150%] roboto-regular text-[#1D2130] opacity-60 mb-4">
                Month End Balance
              </p>
              <Button
                onClick={() => history("/signup")}
                color="secondary"
                className="text-base roboto-bold text-white leading-[150%] py-4 px-10 rounded-xl"
              >
                Get started
              </Button>
            </div>
          </div>
          <div className="w-[295px] py-[48px] rounded-[24px] bg-white border border-[#d8d8d8] flex flex-col gap-[18px]">
            <div className="flex flex-col items-center px-[17px]">
              <img src={calendar} />
              <p className="mt-6 text-[25px] leading-[120%] text-[#1D2130] roboto-bold">
                Signal Only
              </p>
              <p className="text-[16px] leading-[150%] text-[#1D2130] text-center roboto-regular opacity-60">
                {selectedTab === "monthly" ? "Monthly" : "Annual (-25%)"}
                <br />
                <br />
              </p>
            </div>
            <div className="w-full border-t border-[#D8D8D8]"></div>
            <div className="flex flex-col items-center">
              <p className="text-[36px] leading-[120%] roboto-bold text-[#1D2130]">
                ${selectedTab === "monthly" ? 79.99 : 719.99}
              </p>
              <p className="text-[12px] leading-[150%] roboto-regular text-[#1D2130] opacity-60 mb-4">
                {selectedTab === "monthly" ? "Per month" : "Annual"}
              </p>
              <Button
                onClick={() => history("/signup")}
                color="danger"
                className="text-base roboto-bold text-white leading-[150%] py-4 px-10 rounded-xl"
              >
                Get started
              </Button>
            </div>
          </div>
          <div className="w-[295px] py-[48px] rounded-[24px] bg-white border border-[#d8d8d8] flex flex-col gap-[18px]">
            <div className="flex flex-col items-center px-[17px]">
              <img src={chart} />
              <p className="mt-6 text-[25px] leading-[120%] text-[#1D2130] roboto-bold">
                Signal + Auto
              </p>
              <p className="text-[16px] leading-[150%] text-[#1D2130] text-center roboto-regular opacity-60">
                Trading{" "}
                {selectedTab === "monthly" ? "Monthly" : "Annual (-25%)"}
                <br />
                <br />
              </p>
            </div>
            <div className="w-full border-t border-[#D8D8D8]"></div>
            <div className="flex flex-col items-center">
              <p className="text-[36px] leading-[120%] roboto-bold text-[#1D2130]">
                ${selectedTab === "monthly" ? 119 : "1,071"}
              </p>
              <p className="text-[12px] leading-[150%] roboto-regular text-[#1D2130] opacity-60 mb-4">
                {selectedTab === "monthly" ? "Per month" : "Annual (-25%)"}
              </p>
              <Button
                onClick={() => history("/signup")}
                color="secondary"
                className="text-base roboto-bold text-white leading-[150%] py-4 px-10 rounded-xl"
              >
                Get started
              </Button>
            </div>
          </div>
        </div>
        <p className="text-[24px] leading-[120%] roboto-bold text-[#CD2C34]">
          Features of Each Plan
        </p>
        <div className="flex md:flex-row flex-col gap-5">
          <div className="w-[411px] py-[48px] rounded-[24px] bg-white border border-[#d8d8d8] flex flex-col items-center gap-[34px]">
            <div className="flex flex-row gap-6 px-10 items-center w-full ">
              <img src={calendar} />
              <div className="flex flex-col">
                <p className="text-[25px] leading-[120%] text-[#1D2130] roboto-bold">
                  Signal Only
                </p>
                <p className="text-[16px] leading-[150%] text-[#1D2130] text-left roboto-regular opacity-60">
                  Plan
                </p>
              </div>
            </div>
            <div className="w-full border-t border-[#D8D8D8]"></div>
            <div className="flex flex-col w-full px-10 gap-6">
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  Trading signals
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  on-screen alerts
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  SMS/email notifications
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  And an interactive chart
                </p>
              </div>
            </div>
            <Button
              onClick={() => history("/signup")}
              color="secondary"
              className="text-base roboto-bold text-white leading-[150%] py-4 px-10 rounded-xl"
            >
              Get started
            </Button>
          </div>
          <div className="w-[411px] py-[48px] rounded-[24px] bg-white border border-[#d8d8d8] flex flex-col items-center gap-[34px]">
            <div className="flex flex-row gap-6 px-10 items-center w-full ">
              <img src={chart} />
              <div className="flex flex-col">
                <p className="text-[25px] leading-[120%] text-[#1D2130] roboto-bold">
                  Signal + Auto
                </p>
                <p className="text-[16px] leading-[150%] text-[#1D2130] text-left roboto-regular opacity-60">
                  Trading Plans
                </p>
              </div>
            </div>
            <div className="w-full border-t border-[#D8D8D8]"></div>
            <div className="flex flex-col w-full px-10 gap-6">
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  All features from Signal Only
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  plus automated trading
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  priority support
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <Check />
                <p className="text-[16px] leading-[150%] roboto-bold text-[#1D2130]">
                  and profit monitoring
                </p>
              </div>
            </div>
            <Button
              onClick={() => history("/signup")}
              color="secondary"
              className="text-base roboto-bold text-white leading-[150%] py-4 px-10 rounded-xl"
            >
              Get started
            </Button>
          </div>
        </div>
      </div>
      <GetStarted />
      <Contact />
      <FAQ />
      <Footer />
    </div>
  );
}
