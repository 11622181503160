import { Polygon } from "./icons/polygon";

export const TradeStatus = ({ title, value, className }) => {
  return (
    <div className={`flex flex-row gap-[6px] roboto-regular items-center ${className}`}>
      <p className="max-lg:text-[14px]">{title}</p>
      <div
        className={`${
          value < 0 ? "text-[#CD2C34] rotate-180" : "text-[#1DAD32]"
        }`}
      >
        <Polygon />
      </div>
      <p
        className={`${
          value < 0 ? "text-[#CD2C34]" : "text-[#1DAD32]"
        } max-lg:text-[14px]`}
      >
        {value > 0 ? '+' : ''}{value.toFixed(2)}%
      </p>
    </div>
  );
};
