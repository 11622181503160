import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import Cancelled from "./pages/Cancelled";
import Success from "./pages/Success";
import "react-toastify/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Verify from "./pages/Verify";
import Login from "./pages/Login";
import { useDispatch } from "react-redux";
import { checkAuth } from "./store/authSlice";
import { useEffect } from "react";
import withGuest from "./hoc/withGuest";
import ResetPassword from "./pages/ResetPassword";
import PasswordReset from "./pages/PasswordReset";
import StripeReAuth from "./pages/StripeReAuth";
import StripeReturn from "./pages/StripeReturn";
import Feature from "./pages/feature";
import Plan from "./pages/plan";
import Subscription from "./pages/subscription";
import Referrals from "./pages/referrals";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/signup" Component={withGuest(SignUp)} />
        <Route path="/login" Component={withGuest(Login)} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/cancelled" element={<Cancelled />} />
        <Route path="/success" element={<Success />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/stripe-reauth" element={<StripeReAuth />} />
        <Route path="/stripe-return" element={<StripeReturn />} />
        <Route path="/features" element={<Feature />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/subscribe" element={<Subscription />} />
        <Route path="/referrals" element={<Referrals />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
