export const Webull = () => {
  return (
    <div>
      <div className="max-md:hidden">
        <svg
          width="115"
          height="25"
          viewBox="0 0 115 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.7798 4.88654C5.85057 4.78707 5.94964 4.73022 6.07702 4.73022C6.27516 4.73022 6.43084 4.90075 6.445 5.09969C6.445 5.12811 6.445 5.15653 6.43084 5.18495V5.17074C5.05799 10.1443 11.6675 14.1658 21.2916 14.1658C30.9158 14.1658 37.497 10.1727 36.1524 5.19916C36.1383 5.15653 36.1383 5.12811 36.1383 5.08548C36.1383 4.90075 36.294 4.74444 36.4779 4.74444C36.6053 4.74444 36.7327 4.81549 36.7893 4.94338C37.9357 6.52071 38.6009 8.31119 38.6151 10.2011C38.7 16.8231 30.9299 23.3313 21.2633 23.3313C11.5967 23.3313 3.84082 16.7947 3.92574 10.1869C3.95405 8.28277 4.61924 6.49229 5.7798 4.88654Z"
            fill="currentColor"
          />
          <path
            d="M44.3755 5.99502C45.3521 6.36449 46.4135 6.39291 47.4043 6.06607L48.9328 18.8126C48.947 19.0115 48.9469 19.1394 49.046 19.1394C49.1451 19.1394 49.1309 19.0115 49.1734 18.8126L51.0275 11.2244C51.1548 10.7128 51.4521 10.2581 52.3862 10.2581H53.8864C54.8063 10.2581 55.1177 10.7128 55.2451 11.2244L57.0992 18.8126C57.1416 19.0115 57.1416 19.1394 57.2265 19.1394C57.3115 19.1394 57.3115 19.0115 57.3398 18.8126L58.8966 5.95239C59.8449 6.33607 60.9063 6.39291 61.8971 6.09449L59.9015 19.992C59.7033 21.3562 59.0806 21.7399 57.9625 21.7399H56.7878C55.8112 21.7399 55.3017 21.2568 54.9903 20.0063L53.292 12.8727C53.2495 12.6738 53.2495 12.6312 53.1646 12.6312C53.0797 12.6312 53.0514 12.6738 53.0089 12.8727L51.3105 20.0063C51.0133 21.2568 50.4896 21.7399 49.5131 21.7399H48.3525C47.2344 21.7399 46.5975 21.3562 46.4135 19.992L44.3755 5.99502Z"
            fill="currentColor"
          />
          <path
            d="M73.7857 15.942C73.7857 16.283 73.6583 16.482 73.3611 16.482H66.327V16.553C66.327 17.8035 66.8506 19.2956 68.917 19.2956C70.488 19.2956 71.9882 19.1677 72.894 19.0824H72.9648C73.1771 19.0824 73.3469 19.1961 73.3469 19.4093V20.8303C73.3469 21.1713 73.2761 21.3276 72.894 21.3987C71.4929 21.6545 70.6437 21.7681 68.7047 21.7681C66.5534 21.7681 63.4822 20.6029 63.4822 16.3399V14.7483C63.4822 11.3805 65.3645 9.26318 68.6622 9.26318C71.9599 9.26318 73.7998 11.5368 73.7998 14.7483L73.7857 15.942ZM70.9126 14.4499V14.3646C70.9126 12.6736 69.9785 11.7073 68.6481 11.7073C67.3177 11.7073 66.3411 12.6594 66.3411 14.3646V14.4499H70.9126Z"
            fill="currentColor"
          />
          <path
            d="M76.22 5.76758C76.6446 5.89548 77.0833 5.96653 77.5221 5.96653C78.0457 5.96653 78.5694 5.88127 79.0647 5.69653V9.39118C79.7582 9.29171 80.4376 9.24908 81.1311 9.23487C84.6128 9.23487 86.297 11.2811 86.297 14.6489V16.3115C86.297 19.6794 84.6128 21.7398 81.1311 21.7398C79.6733 21.7256 78.2156 21.5267 76.7861 21.1714C76.2907 21.0435 76.2058 20.9156 76.2058 20.5888L76.22 5.76758ZM83.4522 16.3257V14.6632C83.4522 12.8869 82.9993 11.5795 81.1452 11.5795C80.4517 11.5938 79.7582 11.6364 79.0789 11.7359V19.2673C79.7582 19.3809 80.4517 19.4236 81.1452 19.4236C82.9993 19.4236 83.4522 18.102 83.4522 16.3257Z"
            fill="currentColor"
          />
          <path
            d="M98.5395 10.0449V16.5247C98.5395 21.2283 94.888 21.7541 93.6142 21.7541H93.4868C92.213 21.7541 88.5615 21.2425 88.5615 16.5247V9.98804C89.4815 10.3291 90.4722 10.3717 91.4063 10.0875V16.5105C91.4063 18.7557 92.2272 19.2815 93.4868 19.2815H93.6142C94.8597 19.2815 95.6947 18.7699 95.6947 16.5105V10.0307C96.6288 10.3433 97.6195 10.3433 98.5395 10.0449Z"
            fill="currentColor"
          />
          <path
            d="M101.356 5.72485C102.276 6.03748 103.281 6.03748 104.201 5.72485V21.1287C104.215 21.3703 104.031 21.5692 103.79 21.5834H101.781C101.54 21.5692 101.356 21.3703 101.356 21.1287V5.72485Z"
            fill="currentColor"
          />
          <path
            d="M107.229 5.69653C108.149 6.02337 109.14 6.05179 110.074 5.76758V21.1288C110.088 21.3704 109.904 21.5693 109.664 21.5835H107.654C107.413 21.5693 107.229 21.3704 107.229 21.1288V5.69653Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div className="md:hidden">
        <svg
          width="67"
          height="14"
          viewBox="0 0 115 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.7798 4.88654C5.85057 4.78707 5.94964 4.73022 6.07702 4.73022C6.27516 4.73022 6.43084 4.90075 6.445 5.09969C6.445 5.12811 6.445 5.15653 6.43084 5.18495V5.17074C5.05799 10.1443 11.6675 14.1658 21.2916 14.1658C30.9158 14.1658 37.497 10.1727 36.1524 5.19916C36.1383 5.15653 36.1383 5.12811 36.1383 5.08548C36.1383 4.90075 36.294 4.74444 36.4779 4.74444C36.6053 4.74444 36.7327 4.81549 36.7893 4.94338C37.9357 6.52071 38.6009 8.31119 38.6151 10.2011C38.7 16.8231 30.9299 23.3313 21.2633 23.3313C11.5967 23.3313 3.84082 16.7947 3.92574 10.1869C3.95405 8.28277 4.61924 6.49229 5.7798 4.88654Z"
            fill="currentColor"
          />
          <path
            d="M44.3755 5.99502C45.3521 6.36449 46.4135 6.39291 47.4043 6.06607L48.9328 18.8126C48.947 19.0115 48.9469 19.1394 49.046 19.1394C49.1451 19.1394 49.1309 19.0115 49.1734 18.8126L51.0275 11.2244C51.1548 10.7128 51.4521 10.2581 52.3862 10.2581H53.8864C54.8063 10.2581 55.1177 10.7128 55.2451 11.2244L57.0992 18.8126C57.1416 19.0115 57.1416 19.1394 57.2265 19.1394C57.3115 19.1394 57.3115 19.0115 57.3398 18.8126L58.8966 5.95239C59.8449 6.33607 60.9063 6.39291 61.8971 6.09449L59.9015 19.992C59.7033 21.3562 59.0806 21.7399 57.9625 21.7399H56.7878C55.8112 21.7399 55.3017 21.2568 54.9903 20.0063L53.292 12.8727C53.2495 12.6738 53.2495 12.6312 53.1646 12.6312C53.0797 12.6312 53.0514 12.6738 53.0089 12.8727L51.3105 20.0063C51.0133 21.2568 50.4896 21.7399 49.5131 21.7399H48.3525C47.2344 21.7399 46.5975 21.3562 46.4135 19.992L44.3755 5.99502Z"
            fill="currentColor"
          />
          <path
            d="M73.7857 15.942C73.7857 16.283 73.6583 16.482 73.3611 16.482H66.327V16.553C66.327 17.8035 66.8506 19.2956 68.917 19.2956C70.488 19.2956 71.9882 19.1677 72.894 19.0824H72.9648C73.1771 19.0824 73.3469 19.1961 73.3469 19.4093V20.8303C73.3469 21.1713 73.2761 21.3276 72.894 21.3987C71.4929 21.6545 70.6437 21.7681 68.7047 21.7681C66.5534 21.7681 63.4822 20.6029 63.4822 16.3399V14.7483C63.4822 11.3805 65.3645 9.26318 68.6622 9.26318C71.9599 9.26318 73.7998 11.5368 73.7998 14.7483L73.7857 15.942ZM70.9126 14.4499V14.3646C70.9126 12.6736 69.9785 11.7073 68.6481 11.7073C67.3177 11.7073 66.3411 12.6594 66.3411 14.3646V14.4499H70.9126Z"
            fill="currentColor"
          />
          <path
            d="M76.22 5.76758C76.6446 5.89548 77.0833 5.96653 77.5221 5.96653C78.0457 5.96653 78.5694 5.88127 79.0647 5.69653V9.39118C79.7582 9.29171 80.4376 9.24908 81.1311 9.23487C84.6128 9.23487 86.297 11.2811 86.297 14.6489V16.3115C86.297 19.6794 84.6128 21.7398 81.1311 21.7398C79.6733 21.7256 78.2156 21.5267 76.7861 21.1714C76.2907 21.0435 76.2058 20.9156 76.2058 20.5888L76.22 5.76758ZM83.4522 16.3257V14.6632C83.4522 12.8869 82.9993 11.5795 81.1452 11.5795C80.4517 11.5938 79.7582 11.6364 79.0789 11.7359V19.2673C79.7582 19.3809 80.4517 19.4236 81.1452 19.4236C82.9993 19.4236 83.4522 18.102 83.4522 16.3257Z"
            fill="currentColor"
          />
          <path
            d="M98.5395 10.0449V16.5247C98.5395 21.2283 94.888 21.7541 93.6142 21.7541H93.4868C92.213 21.7541 88.5615 21.2425 88.5615 16.5247V9.98804C89.4815 10.3291 90.4722 10.3717 91.4063 10.0875V16.5105C91.4063 18.7557 92.2272 19.2815 93.4868 19.2815H93.6142C94.8597 19.2815 95.6947 18.7699 95.6947 16.5105V10.0307C96.6288 10.3433 97.6195 10.3433 98.5395 10.0449Z"
            fill="currentColor"
          />
          <path
            d="M101.356 5.72485C102.276 6.03748 103.281 6.03748 104.201 5.72485V21.1287C104.215 21.3703 104.031 21.5692 103.79 21.5834H101.781C101.54 21.5692 101.356 21.3703 101.356 21.1287V5.72485Z"
            fill="currentColor"
          />
          <path
            d="M107.229 5.69653C108.149 6.02337 109.14 6.05179 110.074 5.76758V21.1288C110.088 21.3704 109.904 21.5693 109.664 21.5835H107.654C107.413 21.5693 107.229 21.3704 107.229 21.1288V5.69653Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
};
