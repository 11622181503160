import connect from "../assets/feature/connect.png";
import trade from "../assets/feature/trade.png";
import risk from "../assets/feature/risk.png";
import pay from "../assets/feature/pay.png";
import hero from "../assets/feature/hero.png";
import safebox from "../assets/feature/safebox.png";
import invoice from "../assets/feature/invoice.png";
import strategy from "../assets/feature/strategy.png";
import alert from "../assets/feature/alert.png";
import balance from "../assets/feature/balance.png";
import newspaper from "../assets/feature/newspaper.png";
import market from "../assets/feature/market.png";
import chart from "../assets/feature/chart.png";
import exchange from "../assets/feature/exchange.png";
import setting from "../assets/feature/setting.png";
import { GetStarted } from "../components/getstarted";
import { Contact } from "../components/contact";
import { Footer } from "../components/footer";
import { FeatureItem } from "../components/featureitem";
import { Highlight } from "../components/highlight";
import Header from "../components/Header";
import { FAQ } from "../components/faq";

export default function Feature() {
  return (
    <div>
      <Header />
      <div className="relative md:px-[100px] px-5 pb-[92px] pt-[60px] md:pt-[140px] flex flex-col bg-[#F7F7FD] text-[#1D2130]">
        <p className="max-md:text-center md:text-[24px] text-[20px] leading-[120%] text-[#CD2C34] roboto-bold ">
          Feature
        </p>
        <p className="mt-7 md:text-[48px] text-[32px] max-md:text-center max-w-[576px] roboto-medium leading-[120%]">
          Effortless Setup for Hands-Free Trading
        </p>
        <div className="mt-7 flex flex-col gap-[10px]">
          <FeatureItem
            img={connect}
            header="Connect Your TradeStation Account"
            description="Connect your TradeStation account seamlessly through our secure API. TradeStation requires a minimum 
account balance of $10,000 to enable API usage with our platform. While AI Systemics places trades on your 
behalf using our advanced algorithm, your account remains fully under your control. AI Systemics cannot 
withdraw or deposit funds; we are authorized only to execute trades."
          />
          <FeatureItem
            img={trade}
            header="Let the Algorithm Trade and Protect Your Assets"
            description="Take the stress out of trading. Our powerful algorithm executes trades on your behalf with precision, employing 
dynamic stops and targets to protect your assets and maximize your returns."
          />
          <FeatureItem
            img={risk}
            header="Select Your Risk Level"
            description="Tailor your trading experience to fit your risk tolerance. Choose from low, medium, or high-risk strategies, 
empowering you to align your investments with your comfort level and goals."
          />
          <FeatureItem
            img={pay}
            header="Pay on Your Profits, Not on Your Losses"
            description={
              <p>
                With AI Systemics, you only pay a percentage of your
                profits—never on your losses. Any losses are rolled over month
                to month, ensuring you only pay when your account generates
                positive returns. Our profit-sharing model keeps your success at
                the forefront.
                <br /> Note: Only premium accounts are exempt from the monthly
                subscription fee.
              </p>
            }
          />
        </div>
        <img src={hero} className="absolute right-0 bottom-5 max-md:hidden" />
      </div>
      <div className="md:px-[100px] px-5 md:pt-[91px] pt-[40px] pb-[104px] flex flex-col bg-white">
        <p className="md:text-[48px] text-[32px] leading-[120%] roboto-medium text-[#1D2130] text-center">
          Dashboard Highlights
        </p>
        <p className="text-[16px] leading-[150%] mt-[6px] roboto-regular text-[#1D2130] text-center">
          accessible via Heroku-hosted dashboard
        </p>
        <div className="mt-9 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          <Highlight
            img={safebox}
            title="Home"
            description="Overview of account status."
          />
          <Highlight
            img={invoice}
            title="Accounts"
            description="Linked brokerage accounts and API status."
          />
          <Highlight
            img={strategy}
            title="Activity"
            description="Recent trades and updates."
          />
          <Highlight
            img={alert}
            title="Alerts"
            description="Trading alerts and signals."
          />
          <Highlight
            img={balance}
            title="Balances"
            description="Overview of account balances and margins."
          />
          <Highlight
            img={newspaper}
            title="Chart"
            description="Interactive real-time data chart."
          />
          <Highlight
            img={market}
            title="Orders"
            description="Active, completed, and canceled orders."
          />
          <Highlight
            img={chart}
            title="PnL"
            description="Profit and Loss metrics."
          />
          <Highlight
            img={exchange}
            title="Positions"
            description="Current trading positions."
          />
          <Highlight
            img={setting}
            title="Settings"
            description="User account settings."
          />
        </div>
      </div>
      <GetStarted />
      <Contact />
      <FAQ />
      <Footer />
    </div>
  );
}
