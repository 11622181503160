import { Button } from "./button";
import contactbg from "../assets/contact.png";
import { Link } from "react-router-dom";
import { useState } from "react";

export const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const mailtoLink = `mailto:${encodeURIComponent(
      "support@aisystemis.com"
    )}?subject=${encodeURIComponent(name)}&body=${encodeURIComponent(
      phone ? `Phone Number: ${phone}\n` + message : message
    )}`;

    window.location.href = mailtoLink; // Opens the user's default email client
  };
  return (
    <div
      id="supportcontacts"
      className="md:px-[100px] px-[20px] flex flex-col bg-white text-[#1D2130] relative"
    >
      <div className="md:py-[100px] py-[50px] relative flex flex-col">
        <img src={contactbg} className="max-md:hidden" />
        <div className="md:absolute md:left-[120px] md:top-[50%] md:-translate-y-[50%] flex flex-col md:gap-[26px] gap-[17px] ">
          <p className="md:max-w-[467px] max-w-[223px] md:text-white text-[#1D2130] roboto-regular md:text-[44px] md:leading-[60px] max-md:text-center max-md:mx-auto text-[32px] leading-[38.4px]">
            Feel Free Contact Us
          </p>
          <p className="md:max-w-[467px] max-w-[223px] md:text-white text-[#1D2130] roboto-regular md:text-[20px] md:leading-[30px] text-[14px] leading-[20px] max-md:text-center max-md:mx-auto">
          Have questions or need assistance? Fill out this form 
to connect with our team. We're here to help with any 
inquiries, from getting started with AI Systemics to 
understanding our platform's features. Expect a 
prompt response—your success is our priority!”
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="md:right-[121px] md:top-[50%] md:-translate-y-[50%] md:absolute bg-white shadow-[0px_80.6px_105.7px_0px_#3333331a] md:px-10 md:pt-[50px] px-[17px] py-[25px] md:pb-[40px] rounded-[20px] flex flex-col gap-5"
        >
          <input
            className="md:w-[484px] text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
            placeholder="Your Name (required)"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="md:w-[484px] text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
            placeholder="Your Email (required)"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="md:w-[484px] text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
            placeholder="Mobile Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <textarea
            className="md:w-[484px] h-[165px] resize-none text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="flex flex-row gap-3">
            <input type="checkbox" id="accept" className="w-[22px] h-[22px]" required/>
            <label
              htmlFor="accept"
              className="text-[#303030] roboto-regular text-[16px]"
            >
              I have read and understand the{" "}
              <Link
                to="/assets/compliances/Agreement - PRIVACY POLICY.pdf"
                target="_blank"
                className="underline"
              >
                Privacy Policy
              </Link>
              .
            </label>
          </div>
          <div>
            <Button
              type={"submit"}
              className="robot-regular text-[14px] py-[10px] px-[60px] rounded-[10px] w-[155px] h-[50px]"
              color="danger"
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
