export const Input = ({
  startContent,
  endContent,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className="rounded-[5px] border border-[#1D213080] md:py-4 md:pl-2 md:pr-3 py-1 px-2 flex flex-row items-center">
      {startContent}
      <input
        value={value}
        onChange={onChange}
        className="bg-transparent outline-none border-none md:text-[14px] md:leading-[21px] text-[12px] leading-[18px] max-md:w-[80px] min-w-0 max-md:h-5"
        placeholder={placeholder}
      />
      {endContent}
    </div>
  );
};
