  
  export const Tab = ({ onChange, selectedTab }) => {
    return (
      <div className="flex flex-row ">
        <div
          className={`py-[14px] cursor-pointer px-[22px] rounded-l-[10px] ${
            selectedTab === "monthly"
              ? "bg-[#CD2C34] border-transparent hover:bg-[#db575d]"
              : "bg-white border-[#D8D8D8] hover:bg-[#eee]"
          } transition-all border `}
          onClick={() => onChange('monthly')}
        >
          <p
            className={`text-[16px] ${
              selectedTab === "monthly" ? "text-white" : "text-[#1D2130]"
            } transition-all`}
          >
            Monthly
          </p>
        </div>
        <div
          className={`py-[14px] cursor-pointer w-[104px] px-[22px] rounded-r-[10px] ${
            selectedTab === "annual"
              ? "bg-[#CD2C34] border-transparent hover:bg-[#db575d]"
              : "bg-white border-[#D8D8D8] hover:bg-[#eee]"
          } transition-all border `}
          onClick={() => onChange('annual')}
        >
          <p
            className={`text-[16px] ${
              selectedTab === "annual" ? "text-white" : "text-[#1D2130]"
            } transition-all`}
          >
            Annual
          </p>
        </div>
      </div>
    );
  };
  