export const AngleLeft = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.571777"
        y="0.984497"
        width="27.4097"
        height="27.4097"
        rx="13.7048"
        fill="currentColor"
      />
      <path
        d="M11.0894 15.4669C10.823 15.2005 10.823 14.7685 11.0894 14.5021L15.4308 10.1607C15.6972 9.89428 16.1292 9.89428 16.3956 10.1607C16.662 10.4271 16.662 10.859 16.3956 11.1255L12.5365 14.9845L16.3956 18.8435C16.662 19.11 16.662 19.5419 16.3956 19.8083C16.1292 20.0747 15.6972 20.0747 15.4308 19.8083L11.0894 15.4669ZM12.2274 15.6667H11.5718V14.3023H12.2274V15.6667Z"
        fill="#606060"
      />
    </svg>
  );
};
