export const FaqItem = ({ selectedItem, index, isfirst, onClick, title, children }) => {
    return (
      <div className="flex flex-col">
        <div
          className={`bg-[#182A3E] px-5 py-2 flex cursor-pointer flex-row items-center justify-between ${
            isfirst ? "rounded-t-[10px]" : ""
          }`}
          onClick={() => onClick(index)}
        >
          <p className="roboto-regular text-[16px] leading-[24px] text-white">
            {title}
          </p>
        </div>
        <div
          className={`bg-white transition-all ${
            selectedItem === index
              ? "px-[50px] py-[20px]"
              : "h-0 pt-[1px] overflow-hidden"
          } `}
        >
          <div className="roboto-regular">{children}</div>
        </div>
      </div>
    );
  };
  