export const IBKR = () => {
  return (
    <div>
      <div className="max-md:hidden">
        <svg
          width="120"
          height="38"
          viewBox="0 0 120 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_72_859)">
            <path
              d="M29.5415 36.5656H14.2122V19.6912L29.5415 36.5656Z"
              fill="currentColor"
            />
            <path
              d="M27.427 28.4333C29.9646 28.4333 32.0217 26.3762 32.0217 23.8386C32.0217 21.301 29.9646 19.2439 27.427 19.2439C24.8894 19.2439 22.8323 21.301 22.8323 23.8386C22.8323 26.3762 24.8894 28.4333 27.427 28.4333Z"
              fill="currentColor"
            />
            <path
              d="M29.5415 1.88159L14.2122 19.6912V36.5657L29.5415 1.88159Z"
              fill="currentColor"
            />
            <path
              d="M44.7895 36.5251H40.4387V15.4219H44.8301V36.5251H44.7895Z"
              fill="currentColor"
            />
            <path
              d="M64.3881 17.6582C65.0387 18.5528 65.364 19.6506 65.364 20.8705C65.364 22.1716 65.0387 23.1882 64.3881 23.9607C64.0222 24.408 63.4936 24.8146 62.7617 25.1806C63.8595 25.5872 64.6728 26.1971 65.2013 27.051C65.7299 27.9049 66.0146 28.9214 66.0146 30.1413C66.0146 31.4018 65.6893 32.4996 65.0794 33.5161C64.6728 34.1667 64.1848 34.736 63.5749 35.1833C62.8837 35.7118 62.1111 36.0371 61.1759 36.2404C60.2407 36.4438 59.2648 36.5251 58.2076 36.5251H48.6929V15.4219H58.8988C61.5012 15.4219 63.2903 16.1944 64.3881 17.6582ZM52.9216 19.0814V23.7574H58.045C58.9802 23.7574 59.7121 23.5948 60.2813 23.2288C60.8506 22.8629 61.1352 22.253 61.1352 21.3584C61.1352 20.3826 60.7693 19.732 59.9967 19.4067C59.3461 19.2034 58.4922 19.0814 57.5164 19.0814H52.9216ZM52.9216 27.2136V32.8249H58.045C58.9802 32.8249 59.6714 32.7029 60.2 32.4589C61.1352 32.0117 61.5825 31.1171 61.5825 29.816C61.5825 28.7181 61.1352 27.9455 60.2407 27.5389C59.7527 27.295 59.0208 27.173 58.1263 27.173H52.9216V27.2136Z"
              fill="currentColor"
            />
            <path
              d="M68.7388 15.4219H73.0895V24.1234L81.2624 15.4219H86.955L78.2942 24.1234L87.4023 36.5657H81.7097L75.2039 27.3356L73.0895 29.4907V36.5657H68.7388V15.4219Z"
              fill="currentColor"
            />
            <path
              d="M102.406 15.9505C103.179 16.2758 103.83 16.7637 104.399 17.4143C104.846 17.9429 105.212 18.5528 105.456 19.2034C105.7 19.854 105.863 20.5859 105.863 21.4397C105.863 22.4563 105.619 23.4321 105.09 24.408C104.602 25.3839 103.748 26.0751 102.569 26.4817C103.545 26.8884 104.236 27.4169 104.643 28.1489C105.049 28.8808 105.253 29.9786 105.253 31.4424V32.8656C105.253 33.8414 105.293 34.492 105.375 34.8173C105.497 35.3459 105.781 35.7525 106.188 36.0371V36.5657H101.309C101.187 36.0778 101.065 35.7118 101.024 35.4272C100.902 34.8173 100.861 34.248 100.821 33.5975L100.78 31.6457C100.78 30.3039 100.536 29.4094 100.089 28.9621C99.6414 28.5148 98.7875 28.2708 97.5677 28.2708H93.2576V36.5657H88.9475V15.4219H99.0315C100.536 15.4219 101.634 15.6252 102.406 15.9505ZM93.2983 19.0814V24.774H98.0556C98.9908 24.774 99.7227 24.652 100.17 24.4487C101.024 24.0421 101.431 23.2695 101.431 22.0903C101.431 20.8298 101.024 19.9353 100.211 19.5287C99.7634 19.2847 99.0722 19.1627 98.1776 19.1627H93.2983V19.0814Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_72_859">
              <rect
                width="120"
                height="37"
                fill="white"
                transform="translate(0 0.523682)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="md:hidden">
        <svg
          width="71"
          height="22"
          viewBox="0 0 120 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_72_859)">
            <path
              d="M29.5415 36.5656H14.2122V19.6912L29.5415 36.5656Z"
              fill="currentColor"
            />
            <path
              d="M27.427 28.4333C29.9646 28.4333 32.0217 26.3762 32.0217 23.8386C32.0217 21.301 29.9646 19.2439 27.427 19.2439C24.8894 19.2439 22.8323 21.301 22.8323 23.8386C22.8323 26.3762 24.8894 28.4333 27.427 28.4333Z"
              fill="currentColor"
            />
            <path
              d="M29.5415 1.88159L14.2122 19.6912V36.5657L29.5415 1.88159Z"
              fill="currentColor"
            />
            <path
              d="M44.7895 36.5251H40.4387V15.4219H44.8301V36.5251H44.7895Z"
              fill="currentColor"
            />
            <path
              d="M64.3881 17.6582C65.0387 18.5528 65.364 19.6506 65.364 20.8705C65.364 22.1716 65.0387 23.1882 64.3881 23.9607C64.0222 24.408 63.4936 24.8146 62.7617 25.1806C63.8595 25.5872 64.6728 26.1971 65.2013 27.051C65.7299 27.9049 66.0146 28.9214 66.0146 30.1413C66.0146 31.4018 65.6893 32.4996 65.0794 33.5161C64.6728 34.1667 64.1848 34.736 63.5749 35.1833C62.8837 35.7118 62.1111 36.0371 61.1759 36.2404C60.2407 36.4438 59.2648 36.5251 58.2076 36.5251H48.6929V15.4219H58.8988C61.5012 15.4219 63.2903 16.1944 64.3881 17.6582ZM52.9216 19.0814V23.7574H58.045C58.9802 23.7574 59.7121 23.5948 60.2813 23.2288C60.8506 22.8629 61.1352 22.253 61.1352 21.3584C61.1352 20.3826 60.7693 19.732 59.9967 19.4067C59.3461 19.2034 58.4922 19.0814 57.5164 19.0814H52.9216ZM52.9216 27.2136V32.8249H58.045C58.9802 32.8249 59.6714 32.7029 60.2 32.4589C61.1352 32.0117 61.5825 31.1171 61.5825 29.816C61.5825 28.7181 61.1352 27.9455 60.2407 27.5389C59.7527 27.295 59.0208 27.173 58.1263 27.173H52.9216V27.2136Z"
              fill="currentColor"
            />
            <path
              d="M68.7388 15.4219H73.0895V24.1234L81.2624 15.4219H86.955L78.2942 24.1234L87.4023 36.5657H81.7097L75.2039 27.3356L73.0895 29.4907V36.5657H68.7388V15.4219Z"
              fill="currentColor"
            />
            <path
              d="M102.406 15.9505C103.179 16.2758 103.83 16.7637 104.399 17.4143C104.846 17.9429 105.212 18.5528 105.456 19.2034C105.7 19.854 105.863 20.5859 105.863 21.4397C105.863 22.4563 105.619 23.4321 105.09 24.408C104.602 25.3839 103.748 26.0751 102.569 26.4817C103.545 26.8884 104.236 27.4169 104.643 28.1489C105.049 28.8808 105.253 29.9786 105.253 31.4424V32.8656C105.253 33.8414 105.293 34.492 105.375 34.8173C105.497 35.3459 105.781 35.7525 106.188 36.0371V36.5657H101.309C101.187 36.0778 101.065 35.7118 101.024 35.4272C100.902 34.8173 100.861 34.248 100.821 33.5975L100.78 31.6457C100.78 30.3039 100.536 29.4094 100.089 28.9621C99.6414 28.5148 98.7875 28.2708 97.5677 28.2708H93.2576V36.5657H88.9475V15.4219H99.0315C100.536 15.4219 101.634 15.6252 102.406 15.9505ZM93.2983 19.0814V24.774H98.0556C98.9908 24.774 99.7227 24.652 100.17 24.4487C101.024 24.0421 101.431 23.2695 101.431 22.0903C101.431 20.8298 101.024 19.9353 100.211 19.5287C99.7634 19.2847 99.0722 19.1627 98.1776 19.1627H93.2983V19.0814Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_72_859">
              <rect
                width="120"
                height="37"
                fill="white"
                transform="translate(0 0.523682)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};
