export const AngleRight = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="27.4097"
        height="27.4097"
        rx="13.7048"
        transform="matrix(-1 0 0 1 28.3911 0.984497)"
        fill="currentColor"
      />
      <path
        d="M17.8735 15.4669C18.1399 15.2005 18.1399 14.7685 17.8735 14.5021L13.5321 10.1607C13.2657 9.89428 12.8337 9.89428 12.5673 10.1607C12.3009 10.4271 12.3009 10.859 12.5673 11.1255L16.4264 14.9845L12.5673 18.8435C12.3009 19.11 12.3009 19.5419 12.5673 19.8083C12.8337 20.0747 13.2657 20.0747 13.5321 19.8083L17.8735 15.4669ZM16.7355 15.6667H17.3911V14.3023H16.7355V15.6667Z"
        fill="#606060"
      />
    </svg>
  );
};
