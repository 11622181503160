import { useState } from "react";
import { AccordionItem } from "./accordionitem";
import { marketPeriod } from "../utils/dummydata";

export const Accordion = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div className="md:hidden mt-10">
      <AccordionItem
        index={0}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        isfirst
        data={marketPeriod[0]}
      />
      <AccordionItem
        index={1}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        data={marketPeriod[1]}
      />
      <AccordionItem
        index={2}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        data={marketPeriod[2]}
      />
      <AccordionItem
        index={3}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        data={marketPeriod[3]}
      />
      <AccordionItem
        index={4}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        data={marketPeriod[4]}
      />
      <AccordionItem
        index={5}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        data={marketPeriod[5]}
      />
      <AccordionItem
        index={6}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        data={marketPeriod[6]}
      />
      <AccordionItem
        index={7}
        onClick={(index) => setSelectedIndex(index)}
        selectedItem={selectedIndex}
        data={marketPeriod[7]}
      />
    </div>
  );
};
