export const Magnifier = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 12.0643C9.63642 12.0643 11.875 9.82576 11.875 7.06433C11.875 4.30291 9.63642 2.06433 6.875 2.06433C4.11358 2.06433 1.875 4.30291 1.875 7.06433C1.875 9.82576 4.11358 12.0643 6.875 12.0643Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.125 13.3143L10.4062 10.5956"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
