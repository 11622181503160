 
  export const FeatureItem = ({ img, header, description }) => {
    return (
      <div className="max-w-[610px] gap-8 pl-7 py-6 pr-6 border border-transparent bg-white transition-all cursor-pointer hover:border-[#CD2C34] flex flex-row items-start">
        <img src={img} />
        <div className="flex flex-col gap-2 justify-center my-auto">
          <p className="text-[22px] my-auto leading-[140%] text-[#182A3E] roboto-bold">
            {header}
          </p>
          {description && (
            <p className="text-[18px] leading-[150%] text-[#1D2130] roboto-regular">
              {description}
            </p>
          )}
        </div>
      </div>
    );
  };
  