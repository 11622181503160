export const Tradestation = () => {
  return (
    <div>
      <div className="md:hidden">
        <svg
          width="90"
          height="25"
          viewBox="0 0 160 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_72_838)">
            <mask
              id="mask0_72_838"
              //   style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="160"
              height="25"
            >
              <path
                d="M159.867 0.0236816H0V24.0237H159.867V0.0236816Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_72_838)">
              <path
                d="M12.8087 7.85132L7.16382 23.6402C12.0631 23.6402 16.0571 19.673 16.0571 14.7473C16.0571 11.9783 14.8057 9.50209 12.8087 7.85132Z"
                fill="currentColor"
              />
              <path
                d="M15.3883 0.582275L13.4978 5.90734H17.8113C20.7403 5.90734 23.1367 3.53769 23.1367 0.582275H15.3883Z"
                fill="currentColor"
              />
              <path
                d="M5.35196 0.582275C2.3964 0.582275 0 2.97855 0 5.93396H6.09751C5.61823 7.0256 5.35196 8.22374 5.35196 9.47513C5.35196 12.0312 6.41703 14.321 8.14777 15.9451L13.6329 0.582275H5.35196Z"
                fill="currentColor"
              />
              <path
                d="M65.4987 16.877C64.5667 16.877 63.7147 16.6374 62.9958 16.1847C62.2502 15.7055 61.6644 15.0398 61.2384 14.1878C60.8124 13.3358 60.5994 12.3241 60.5994 11.2058C60.5994 10.1408 60.8124 9.18225 61.2384 8.30362C61.6644 7.42498 62.2502 6.73272 63.0224 6.20022C63.7946 5.66771 64.6732 5.42808 65.6318 5.42808C66.2176 5.42808 66.7501 5.53458 67.256 5.77421C67.7619 6.01384 68.188 6.33334 68.5075 6.75935V0.582275H72.2618V16.5575H68.7205L68.6672 15.3327C68.3477 15.8386 67.9217 16.2114 67.3625 16.5042C66.83 16.7439 66.191 16.877 65.4987 16.877ZM66.5371 13.5222C67.1761 13.5222 67.7087 13.3092 68.1081 12.8566C68.5075 12.4039 68.7205 11.8448 68.7205 11.1525C68.7205 10.4603 68.5075 9.87451 68.1081 9.44851C67.7087 8.99587 67.1761 8.78287 66.5371 8.78287C65.8981 8.78287 65.3655 8.99587 64.9661 9.44851C64.5667 9.90114 64.3537 10.4603 64.3537 11.1525C64.3537 11.8448 64.5667 12.4306 64.9661 12.8566C65.3655 13.3092 65.8981 13.5222 66.5371 13.5222Z"
                fill="currentColor"
              />
              <path
                d="M129.774 4.07073C129.188 4.07073 128.682 3.88435 128.256 3.48497C127.857 3.11222 127.644 2.63296 127.644 2.0472C127.644 1.48807 127.857 0.982194 128.256 0.609439C128.656 0.210059 129.162 0.0236816 129.774 0.0236816C130.386 0.0236816 130.866 0.210059 131.292 0.609439C131.691 1.00882 131.904 1.48807 131.904 2.0472C131.904 2.60634 131.691 3.08559 131.292 3.48497C130.866 3.85773 130.36 4.07073 129.774 4.07073ZM127.883 5.74813H131.638V16.5314H127.883V5.74813Z"
                fill="currentColor"
              />
              <path
                d="M138.751 16.8759C137.66 16.8759 136.675 16.6363 135.769 16.157C134.864 15.6778 134.145 15.0121 133.613 14.1335C133.08 13.2549 132.814 12.2697 132.814 11.1515C132.814 10.1131 133.053 9.15454 133.559 8.27591C134.065 7.39727 134.757 6.70502 135.663 6.19913C136.568 5.69325 137.607 5.427 138.751 5.427C139.843 5.427 140.828 5.66663 141.734 6.14588C142.639 6.62514 143.358 7.29077 143.89 8.16941C144.423 9.04804 144.689 10.0332 144.689 11.1515C144.689 12.1898 144.45 13.1484 143.97 14.027C143.491 14.879 142.772 15.5979 141.867 16.1038C140.935 16.6097 139.923 16.8759 138.751 16.8759ZM138.751 13.5211C139.39 13.5211 139.923 13.3081 140.322 12.8555C140.722 12.4029 140.935 11.8437 140.935 11.1515C140.935 10.4592 140.722 9.87343 140.322 9.44742C139.923 8.99479 139.39 8.78179 138.751 8.78179C138.112 8.78179 137.58 8.99479 137.18 9.44742C136.781 9.90005 136.568 10.4592 136.568 11.1515C136.568 11.8437 136.781 12.4295 137.18 12.8555C137.58 13.3081 138.112 13.5211 138.751 13.5211Z"
                fill="currentColor"
              />
              <path
                d="M145.759 5.74677H149.433L149.486 7.07803C149.753 6.54552 150.152 6.14614 150.711 5.85326C151.244 5.56038 151.883 5.40063 152.602 5.40063C153.853 5.40063 154.785 5.80001 155.424 6.57215C156.063 7.34429 156.383 8.48917 156.383 9.98019V16.53H152.575V10.6725C152.575 9.39443 152.096 8.75542 151.137 8.75542C150.631 8.75542 150.232 8.9418 149.939 9.28793C149.646 9.63406 149.513 10.1666 149.513 10.8322V16.53H145.759V5.74677Z"
                fill="currentColor"
              />
              <path
                d="M96.3346 8.70268C95.5092 7.90392 94.1778 7.23828 92.2873 6.67915C91.3288 6.4129 90.6897 6.14665 90.3702 5.93364C90.0507 5.72064 89.8909 5.40114 89.8909 5.02838C89.8909 4.0965 90.6098 3.64387 92.0743 3.64387C92.7666 3.64387 93.4855 3.75037 94.2843 3.96337C94.8435 4.12312 95.3494 4.3095 95.7754 4.5225L96.8405 1.51384C96.2281 1.14109 95.4825 0.848208 94.5772 0.60858C93.6719 0.395577 92.7932 0.262451 91.8879 0.262451C90.0241 0.262451 88.533 0.688456 87.4679 1.56709C86.3762 2.4191 85.8437 3.61724 85.8437 5.10826C85.8437 6.4129 86.2165 7.47791 86.9886 8.27667C87.7608 9.07543 88.9856 9.71444 90.6897 10.2203C91.382 10.4067 91.9146 10.5931 92.314 10.7528C92.6867 10.9126 92.9796 11.099 93.1394 11.2854C93.3258 11.4717 93.4056 11.7114 93.4056 12.0042C93.4056 12.4835 93.1926 12.8562 92.7666 13.0959C92.3406 13.3621 91.7548 13.4953 90.956 13.4953C90.1039 13.4953 89.1986 13.3621 88.2667 13.0959C87.6543 12.9361 87.1218 12.7231 86.6158 12.4835L85.5508 15.4922C85.9502 15.7584 86.4295 15.9714 87.0419 16.1844C87.6543 16.3974 88.32 16.5572 89.0389 16.6637C89.7578 16.7702 90.4501 16.8234 91.1424 16.8234C93.1394 16.8234 94.7104 16.3974 95.8287 15.5188C96.947 14.6401 97.5062 13.4154 97.5062 11.8445C97.5594 10.5665 97.16 9.50144 96.3346 8.70268Z"
                fill="currentColor"
              />
              <path
                d="M84.0353 13.548L84.0087 13.2285C83.5294 13.4415 82.9969 13.6279 82.3845 13.761C81.7721 13.8941 81.1597 13.9474 80.5739 13.9474C79.6419 13.9474 78.8964 13.7876 78.3638 13.4948C77.8313 13.2019 77.4585 12.7226 77.2722 12.1102H84.3815C84.4614 11.7375 84.5146 11.3115 84.5146 10.8056C84.5146 9.15481 84.0353 7.82354 83.0768 6.86504C82.1182 5.90652 80.8401 5.40063 79.2159 5.40063C78.0976 5.40063 77.0858 5.64026 76.2337 6.14614C75.3817 6.65202 74.716 7.34429 74.2633 8.1963C73.8107 9.04831 73.571 10.0335 73.571 11.1251C73.571 12.3232 73.8373 13.3616 74.3698 14.2136C74.9024 15.0656 75.6746 15.7313 76.6331 16.1839C77.6183 16.6365 78.7633 16.8762 80.068 16.8762C80.8668 16.8762 81.6389 16.7963 82.3845 16.6632C82.9969 16.5567 83.5561 16.3969 84.062 16.1839V13.548H84.0353ZM79.1094 8.06317C79.6153 8.06317 80.0413 8.22292 80.3875 8.54242C80.707 8.86192 80.8934 9.31456 80.9733 9.87369H77.1923C77.4053 8.64892 78.0443 8.06317 79.1094 8.06317Z"
                fill="currentColor"
              />
              <path
                d="M57.9399 6.73164C57.008 5.85301 55.6767 5.427 53.8927 5.427C53.1471 5.427 52.4016 5.5335 51.656 5.71988C51.0436 5.87963 50.5377 6.06601 50.1117 6.27901V9.3143C51.0969 8.67529 52.1886 8.32916 53.3335 8.32916C54.1323 8.32916 54.7181 8.48891 55.1441 8.83504C55.5435 9.18117 55.7832 9.68705 55.8098 10.3793C55.437 10.2196 55.0376 10.1131 54.585 10.0066C54.1323 9.92668 53.7063 9.87343 53.3069 9.87343C52.0554 9.87343 51.0436 10.193 50.2714 10.8586C49.4993 11.5242 49.0999 12.3762 49.0999 13.4679C49.0999 14.5063 49.4194 15.3316 50.085 15.944C50.7241 16.5564 51.6028 16.8493 52.7211 16.8493C54.1057 16.8493 55.1707 16.3967 55.8897 15.4648L55.9163 16.5298H59.3245V10.5125C59.3245 8.86167 58.8719 7.58365 57.9399 6.73164ZM55.1974 13.8672C54.8512 14.2134 54.4252 14.3998 53.8927 14.3998C53.4933 14.3998 53.1737 14.2933 52.9075 14.0802C52.6678 13.8672 52.5347 13.601 52.5347 13.2549C52.5347 12.9087 52.6678 12.6159 52.9607 12.4295C53.2536 12.2165 53.6264 12.11 54.1323 12.11C54.7181 12.11 55.2773 12.2165 55.7832 12.4295C55.7299 13.0152 55.5435 13.4945 55.1974 13.8672Z"
                fill="currentColor"
              />
              <path
                d="M116.172 6.73164C115.24 5.85301 113.909 5.427 112.125 5.427C111.379 5.427 110.634 5.5335 109.888 5.71988C109.276 5.87963 108.77 6.06601 108.344 6.27901V9.3143C109.329 8.67529 110.421 8.32916 111.566 8.32916C112.364 8.32916 112.95 8.48891 113.376 8.83504C113.776 9.18117 114.015 9.68705 114.042 10.3793C113.669 10.2196 113.27 10.1131 112.817 10.0066C112.364 9.92668 111.938 9.87343 111.539 9.87343C110.288 9.87343 109.276 10.193 108.504 10.8586C107.731 11.5242 107.332 12.3762 107.332 13.4679C107.332 14.5063 107.652 15.3316 108.317 15.944C108.956 16.5564 109.835 16.8493 110.953 16.8493C112.338 16.8493 113.403 16.3967 114.122 15.4648L114.175 16.5032H117.583V10.5125C117.557 8.86167 117.104 7.58365 116.172 6.73164ZM113.43 13.8672C113.083 14.2134 112.657 14.3998 112.125 14.3998C111.725 14.3998 111.406 14.2933 111.14 14.0802C110.9 13.8672 110.767 13.601 110.767 13.2549C110.767 12.9087 110.9 12.6159 111.193 12.4295C111.486 12.2165 111.859 12.11 112.364 12.11C112.95 12.11 113.509 12.2165 114.015 12.4295C113.962 13.0152 113.776 13.4945 113.43 13.8672Z"
                fill="currentColor"
              />
              <path
                d="M106.082 13.1235C105.603 13.3898 105.017 13.5229 104.325 13.5229C103.819 13.5229 103.446 13.3898 103.233 13.1235C103.02 12.8573 102.913 12.4046 102.913 11.7656V8.78357H102.887V8.73031H103.419C105.07 8.73031 106.402 7.39905 106.402 5.74828H102.913V2.97925H99.1591V5.74828H98.0674V8.73031H99.2656V8.78357H99.1591V12.6443C99.1591 14.082 99.5052 15.147 100.171 15.8393C100.837 16.5316 101.875 16.8777 103.233 16.8777C103.872 16.8777 104.484 16.8244 105.044 16.7179C105.603 16.6114 106.082 16.4517 106.428 16.2653L106.082 13.1235Z"
                fill="currentColor"
              />
              <path
                d="M126.238 13.1235C125.759 13.3898 125.173 13.5229 124.481 13.5229C123.975 13.5229 123.602 13.3898 123.389 13.1235C123.176 12.8573 123.069 12.4046 123.069 11.7656V8.78357H123.043V8.73031H123.575C125.226 8.73031 126.558 7.39905 126.558 5.74828H123.069V2.97925H119.315V5.74828H118.223V8.73031H119.422V8.78357H119.315V12.6443C119.315 14.082 119.661 15.147 120.327 15.8393C120.993 16.5316 122.031 16.8777 123.389 16.8777C124.028 16.8777 124.64 16.8244 125.2 16.7179C125.759 16.6114 126.238 16.4517 126.584 16.2653L126.238 13.1235Z"
                fill="currentColor"
              />
              <path
                d="M38.8495 0.661635H36.2401H34.9886H34.5093H32.326C30.4355 0.661635 28.8911 2.2059 28.8911 4.0963H33.2845H33.7904V16.5303H38.0241V4.06967H39.8347C41.7252 4.06967 43.2695 2.52541 43.2695 0.63501H38.8495V0.661635Z"
                fill="currentColor"
              />
              <path
                d="M48.7012 5.66729C48.435 5.58742 48.1154 5.56079 47.7959 5.56079C47.1835 5.56079 46.651 5.72054 46.1983 6.04005C45.7457 6.35955 45.3729 6.81218 45.1066 7.39794L45.0534 5.80042H41.4055V16.5837H45.1599V11.9243C45.1599 10.9657 45.3463 10.2735 45.719 9.76759C46.0918 9.28833 46.6243 9.04871 47.3699 9.04871C47.8492 9.04871 48.3018 9.12858 48.7279 9.31496V5.66729H48.7012Z"
                fill="currentColor"
              />
              <path
                d="M158.082 3.82973C157.763 3.82973 157.47 3.74985 157.204 3.61672C156.937 3.45697 156.724 3.24397 156.565 2.97772C156.405 2.71146 156.325 2.39196 156.325 2.07246C156.325 1.75295 156.405 1.46007 156.538 1.19382C156.698 0.927568 156.911 0.714566 157.177 0.554814C157.443 0.395062 157.763 0.315186 158.082 0.315186C158.402 0.315186 158.695 0.395062 158.961 0.554814C159.227 0.714566 159.44 0.927568 159.6 1.19382C159.76 1.46007 159.84 1.77958 159.84 2.09908C159.84 2.41858 159.76 2.71146 159.6 2.97772C159.44 3.24397 159.227 3.45697 158.961 3.61672C158.721 3.74985 158.428 3.82973 158.082 3.82973ZM158.082 3.53685C158.349 3.53685 158.588 3.4836 158.801 3.35047C159.014 3.21734 159.201 3.05759 159.334 2.81796C159.467 2.60496 159.547 2.33871 159.547 2.07246C159.547 1.8062 159.494 1.56657 159.36 1.35357C159.227 1.14057 159.067 0.954193 158.854 0.821066C158.641 0.687939 158.375 0.608064 158.109 0.608064C157.843 0.608064 157.603 0.661315 157.39 0.794442C157.177 0.927568 156.991 1.08732 156.857 1.32695C156.724 1.53995 156.644 1.8062 156.644 2.07246C156.644 2.33871 156.698 2.57834 156.831 2.79134C156.964 3.00434 157.124 3.19072 157.337 3.32384C157.576 3.45697 157.816 3.53685 158.082 3.53685ZM157.497 1.19382H158.056C158.269 1.19382 158.428 1.24707 158.535 1.32695C158.641 1.43345 158.721 1.53995 158.721 1.72633C158.721 1.96595 158.615 2.12571 158.428 2.20558L158.854 2.87121H158.508L158.136 2.25883H157.816V2.87121H157.523V1.19382H157.497ZM157.789 1.99258H158.029C158.162 1.99258 158.269 1.96595 158.322 1.93933C158.375 1.88608 158.402 1.83283 158.402 1.72633C158.402 1.53995 158.269 1.46007 158.029 1.46007H157.789V1.99258Z"
                fill="currentColor"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_72_838">
              <rect
                width="160"
                height="24"
                fill="white"
                transform="translate(0 0.0236816)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="max-md:hidden">
        <svg
          width="160"
          height="25"
          viewBox="0 0 160 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_72_838)">
            <mask
              id="mask0_72_838"
              //   style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="160"
              height="25"
            >
              <path
                d="M159.867 0.0236816H0V24.0237H159.867V0.0236816Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_72_838)">
              <path
                d="M12.8087 7.85132L7.16382 23.6402C12.0631 23.6402 16.0571 19.673 16.0571 14.7473C16.0571 11.9783 14.8057 9.50209 12.8087 7.85132Z"
                fill="currentColor"
              />
              <path
                d="M15.3883 0.582275L13.4978 5.90734H17.8113C20.7403 5.90734 23.1367 3.53769 23.1367 0.582275H15.3883Z"
                fill="currentColor"
              />
              <path
                d="M5.35196 0.582275C2.3964 0.582275 0 2.97855 0 5.93396H6.09751C5.61823 7.0256 5.35196 8.22374 5.35196 9.47513C5.35196 12.0312 6.41703 14.321 8.14777 15.9451L13.6329 0.582275H5.35196Z"
                fill="currentColor"
              />
              <path
                d="M65.4987 16.877C64.5667 16.877 63.7147 16.6374 62.9958 16.1847C62.2502 15.7055 61.6644 15.0398 61.2384 14.1878C60.8124 13.3358 60.5994 12.3241 60.5994 11.2058C60.5994 10.1408 60.8124 9.18225 61.2384 8.30362C61.6644 7.42498 62.2502 6.73272 63.0224 6.20022C63.7946 5.66771 64.6732 5.42808 65.6318 5.42808C66.2176 5.42808 66.7501 5.53458 67.256 5.77421C67.7619 6.01384 68.188 6.33334 68.5075 6.75935V0.582275H72.2618V16.5575H68.7205L68.6672 15.3327C68.3477 15.8386 67.9217 16.2114 67.3625 16.5042C66.83 16.7439 66.191 16.877 65.4987 16.877ZM66.5371 13.5222C67.1761 13.5222 67.7087 13.3092 68.1081 12.8566C68.5075 12.4039 68.7205 11.8448 68.7205 11.1525C68.7205 10.4603 68.5075 9.87451 68.1081 9.44851C67.7087 8.99587 67.1761 8.78287 66.5371 8.78287C65.8981 8.78287 65.3655 8.99587 64.9661 9.44851C64.5667 9.90114 64.3537 10.4603 64.3537 11.1525C64.3537 11.8448 64.5667 12.4306 64.9661 12.8566C65.3655 13.3092 65.8981 13.5222 66.5371 13.5222Z"
                fill="currentColor"
              />
              <path
                d="M129.774 4.07073C129.188 4.07073 128.682 3.88435 128.256 3.48497C127.857 3.11222 127.644 2.63296 127.644 2.0472C127.644 1.48807 127.857 0.982194 128.256 0.609439C128.656 0.210059 129.162 0.0236816 129.774 0.0236816C130.386 0.0236816 130.866 0.210059 131.292 0.609439C131.691 1.00882 131.904 1.48807 131.904 2.0472C131.904 2.60634 131.691 3.08559 131.292 3.48497C130.866 3.85773 130.36 4.07073 129.774 4.07073ZM127.883 5.74813H131.638V16.5314H127.883V5.74813Z"
                fill="currentColor"
              />
              <path
                d="M138.751 16.8759C137.66 16.8759 136.675 16.6363 135.769 16.157C134.864 15.6778 134.145 15.0121 133.613 14.1335C133.08 13.2549 132.814 12.2697 132.814 11.1515C132.814 10.1131 133.053 9.15454 133.559 8.27591C134.065 7.39727 134.757 6.70502 135.663 6.19913C136.568 5.69325 137.607 5.427 138.751 5.427C139.843 5.427 140.828 5.66663 141.734 6.14588C142.639 6.62514 143.358 7.29077 143.89 8.16941C144.423 9.04804 144.689 10.0332 144.689 11.1515C144.689 12.1898 144.45 13.1484 143.97 14.027C143.491 14.879 142.772 15.5979 141.867 16.1038C140.935 16.6097 139.923 16.8759 138.751 16.8759ZM138.751 13.5211C139.39 13.5211 139.923 13.3081 140.322 12.8555C140.722 12.4029 140.935 11.8437 140.935 11.1515C140.935 10.4592 140.722 9.87343 140.322 9.44742C139.923 8.99479 139.39 8.78179 138.751 8.78179C138.112 8.78179 137.58 8.99479 137.18 9.44742C136.781 9.90005 136.568 10.4592 136.568 11.1515C136.568 11.8437 136.781 12.4295 137.18 12.8555C137.58 13.3081 138.112 13.5211 138.751 13.5211Z"
                fill="currentColor"
              />
              <path
                d="M145.759 5.74677H149.433L149.486 7.07803C149.753 6.54552 150.152 6.14614 150.711 5.85326C151.244 5.56038 151.883 5.40063 152.602 5.40063C153.853 5.40063 154.785 5.80001 155.424 6.57215C156.063 7.34429 156.383 8.48917 156.383 9.98019V16.53H152.575V10.6725C152.575 9.39443 152.096 8.75542 151.137 8.75542C150.631 8.75542 150.232 8.9418 149.939 9.28793C149.646 9.63406 149.513 10.1666 149.513 10.8322V16.53H145.759V5.74677Z"
                fill="currentColor"
              />
              <path
                d="M96.3346 8.70268C95.5092 7.90392 94.1778 7.23828 92.2873 6.67915C91.3288 6.4129 90.6897 6.14665 90.3702 5.93364C90.0507 5.72064 89.8909 5.40114 89.8909 5.02838C89.8909 4.0965 90.6098 3.64387 92.0743 3.64387C92.7666 3.64387 93.4855 3.75037 94.2843 3.96337C94.8435 4.12312 95.3494 4.3095 95.7754 4.5225L96.8405 1.51384C96.2281 1.14109 95.4825 0.848208 94.5772 0.60858C93.6719 0.395577 92.7932 0.262451 91.8879 0.262451C90.0241 0.262451 88.533 0.688456 87.4679 1.56709C86.3762 2.4191 85.8437 3.61724 85.8437 5.10826C85.8437 6.4129 86.2165 7.47791 86.9886 8.27667C87.7608 9.07543 88.9856 9.71444 90.6897 10.2203C91.382 10.4067 91.9146 10.5931 92.314 10.7528C92.6867 10.9126 92.9796 11.099 93.1394 11.2854C93.3258 11.4717 93.4056 11.7114 93.4056 12.0042C93.4056 12.4835 93.1926 12.8562 92.7666 13.0959C92.3406 13.3621 91.7548 13.4953 90.956 13.4953C90.1039 13.4953 89.1986 13.3621 88.2667 13.0959C87.6543 12.9361 87.1218 12.7231 86.6158 12.4835L85.5508 15.4922C85.9502 15.7584 86.4295 15.9714 87.0419 16.1844C87.6543 16.3974 88.32 16.5572 89.0389 16.6637C89.7578 16.7702 90.4501 16.8234 91.1424 16.8234C93.1394 16.8234 94.7104 16.3974 95.8287 15.5188C96.947 14.6401 97.5062 13.4154 97.5062 11.8445C97.5594 10.5665 97.16 9.50144 96.3346 8.70268Z"
                fill="currentColor"
              />
              <path
                d="M84.0353 13.548L84.0087 13.2285C83.5294 13.4415 82.9969 13.6279 82.3845 13.761C81.7721 13.8941 81.1597 13.9474 80.5739 13.9474C79.6419 13.9474 78.8964 13.7876 78.3638 13.4948C77.8313 13.2019 77.4585 12.7226 77.2722 12.1102H84.3815C84.4614 11.7375 84.5146 11.3115 84.5146 10.8056C84.5146 9.15481 84.0353 7.82354 83.0768 6.86504C82.1182 5.90652 80.8401 5.40063 79.2159 5.40063C78.0976 5.40063 77.0858 5.64026 76.2337 6.14614C75.3817 6.65202 74.716 7.34429 74.2633 8.1963C73.8107 9.04831 73.571 10.0335 73.571 11.1251C73.571 12.3232 73.8373 13.3616 74.3698 14.2136C74.9024 15.0656 75.6746 15.7313 76.6331 16.1839C77.6183 16.6365 78.7633 16.8762 80.068 16.8762C80.8668 16.8762 81.6389 16.7963 82.3845 16.6632C82.9969 16.5567 83.5561 16.3969 84.062 16.1839V13.548H84.0353ZM79.1094 8.06317C79.6153 8.06317 80.0413 8.22292 80.3875 8.54242C80.707 8.86192 80.8934 9.31456 80.9733 9.87369H77.1923C77.4053 8.64892 78.0443 8.06317 79.1094 8.06317Z"
                fill="currentColor"
              />
              <path
                d="M57.9399 6.73164C57.008 5.85301 55.6767 5.427 53.8927 5.427C53.1471 5.427 52.4016 5.5335 51.656 5.71988C51.0436 5.87963 50.5377 6.06601 50.1117 6.27901V9.3143C51.0969 8.67529 52.1886 8.32916 53.3335 8.32916C54.1323 8.32916 54.7181 8.48891 55.1441 8.83504C55.5435 9.18117 55.7832 9.68705 55.8098 10.3793C55.437 10.2196 55.0376 10.1131 54.585 10.0066C54.1323 9.92668 53.7063 9.87343 53.3069 9.87343C52.0554 9.87343 51.0436 10.193 50.2714 10.8586C49.4993 11.5242 49.0999 12.3762 49.0999 13.4679C49.0999 14.5063 49.4194 15.3316 50.085 15.944C50.7241 16.5564 51.6028 16.8493 52.7211 16.8493C54.1057 16.8493 55.1707 16.3967 55.8897 15.4648L55.9163 16.5298H59.3245V10.5125C59.3245 8.86167 58.8719 7.58365 57.9399 6.73164ZM55.1974 13.8672C54.8512 14.2134 54.4252 14.3998 53.8927 14.3998C53.4933 14.3998 53.1737 14.2933 52.9075 14.0802C52.6678 13.8672 52.5347 13.601 52.5347 13.2549C52.5347 12.9087 52.6678 12.6159 52.9607 12.4295C53.2536 12.2165 53.6264 12.11 54.1323 12.11C54.7181 12.11 55.2773 12.2165 55.7832 12.4295C55.7299 13.0152 55.5435 13.4945 55.1974 13.8672Z"
                fill="currentColor"
              />
              <path
                d="M116.172 6.73164C115.24 5.85301 113.909 5.427 112.125 5.427C111.379 5.427 110.634 5.5335 109.888 5.71988C109.276 5.87963 108.77 6.06601 108.344 6.27901V9.3143C109.329 8.67529 110.421 8.32916 111.566 8.32916C112.364 8.32916 112.95 8.48891 113.376 8.83504C113.776 9.18117 114.015 9.68705 114.042 10.3793C113.669 10.2196 113.27 10.1131 112.817 10.0066C112.364 9.92668 111.938 9.87343 111.539 9.87343C110.288 9.87343 109.276 10.193 108.504 10.8586C107.731 11.5242 107.332 12.3762 107.332 13.4679C107.332 14.5063 107.652 15.3316 108.317 15.944C108.956 16.5564 109.835 16.8493 110.953 16.8493C112.338 16.8493 113.403 16.3967 114.122 15.4648L114.175 16.5032H117.583V10.5125C117.557 8.86167 117.104 7.58365 116.172 6.73164ZM113.43 13.8672C113.083 14.2134 112.657 14.3998 112.125 14.3998C111.725 14.3998 111.406 14.2933 111.14 14.0802C110.9 13.8672 110.767 13.601 110.767 13.2549C110.767 12.9087 110.9 12.6159 111.193 12.4295C111.486 12.2165 111.859 12.11 112.364 12.11C112.95 12.11 113.509 12.2165 114.015 12.4295C113.962 13.0152 113.776 13.4945 113.43 13.8672Z"
                fill="currentColor"
              />
              <path
                d="M106.082 13.1235C105.603 13.3898 105.017 13.5229 104.325 13.5229C103.819 13.5229 103.446 13.3898 103.233 13.1235C103.02 12.8573 102.913 12.4046 102.913 11.7656V8.78357H102.887V8.73031H103.419C105.07 8.73031 106.402 7.39905 106.402 5.74828H102.913V2.97925H99.1591V5.74828H98.0674V8.73031H99.2656V8.78357H99.1591V12.6443C99.1591 14.082 99.5052 15.147 100.171 15.8393C100.837 16.5316 101.875 16.8777 103.233 16.8777C103.872 16.8777 104.484 16.8244 105.044 16.7179C105.603 16.6114 106.082 16.4517 106.428 16.2653L106.082 13.1235Z"
                fill="currentColor"
              />
              <path
                d="M126.238 13.1235C125.759 13.3898 125.173 13.5229 124.481 13.5229C123.975 13.5229 123.602 13.3898 123.389 13.1235C123.176 12.8573 123.069 12.4046 123.069 11.7656V8.78357H123.043V8.73031H123.575C125.226 8.73031 126.558 7.39905 126.558 5.74828H123.069V2.97925H119.315V5.74828H118.223V8.73031H119.422V8.78357H119.315V12.6443C119.315 14.082 119.661 15.147 120.327 15.8393C120.993 16.5316 122.031 16.8777 123.389 16.8777C124.028 16.8777 124.64 16.8244 125.2 16.7179C125.759 16.6114 126.238 16.4517 126.584 16.2653L126.238 13.1235Z"
                fill="currentColor"
              />
              <path
                d="M38.8495 0.661635H36.2401H34.9886H34.5093H32.326C30.4355 0.661635 28.8911 2.2059 28.8911 4.0963H33.2845H33.7904V16.5303H38.0241V4.06967H39.8347C41.7252 4.06967 43.2695 2.52541 43.2695 0.63501H38.8495V0.661635Z"
                fill="currentColor"
              />
              <path
                d="M48.7012 5.66729C48.435 5.58742 48.1154 5.56079 47.7959 5.56079C47.1835 5.56079 46.651 5.72054 46.1983 6.04005C45.7457 6.35955 45.3729 6.81218 45.1066 7.39794L45.0534 5.80042H41.4055V16.5837H45.1599V11.9243C45.1599 10.9657 45.3463 10.2735 45.719 9.76759C46.0918 9.28833 46.6243 9.04871 47.3699 9.04871C47.8492 9.04871 48.3018 9.12858 48.7279 9.31496V5.66729H48.7012Z"
                fill="currentColor"
              />
              <path
                d="M158.082 3.82973C157.763 3.82973 157.47 3.74985 157.204 3.61672C156.937 3.45697 156.724 3.24397 156.565 2.97772C156.405 2.71146 156.325 2.39196 156.325 2.07246C156.325 1.75295 156.405 1.46007 156.538 1.19382C156.698 0.927568 156.911 0.714566 157.177 0.554814C157.443 0.395062 157.763 0.315186 158.082 0.315186C158.402 0.315186 158.695 0.395062 158.961 0.554814C159.227 0.714566 159.44 0.927568 159.6 1.19382C159.76 1.46007 159.84 1.77958 159.84 2.09908C159.84 2.41858 159.76 2.71146 159.6 2.97772C159.44 3.24397 159.227 3.45697 158.961 3.61672C158.721 3.74985 158.428 3.82973 158.082 3.82973ZM158.082 3.53685C158.349 3.53685 158.588 3.4836 158.801 3.35047C159.014 3.21734 159.201 3.05759 159.334 2.81796C159.467 2.60496 159.547 2.33871 159.547 2.07246C159.547 1.8062 159.494 1.56657 159.36 1.35357C159.227 1.14057 159.067 0.954193 158.854 0.821066C158.641 0.687939 158.375 0.608064 158.109 0.608064C157.843 0.608064 157.603 0.661315 157.39 0.794442C157.177 0.927568 156.991 1.08732 156.857 1.32695C156.724 1.53995 156.644 1.8062 156.644 2.07246C156.644 2.33871 156.698 2.57834 156.831 2.79134C156.964 3.00434 157.124 3.19072 157.337 3.32384C157.576 3.45697 157.816 3.53685 158.082 3.53685ZM157.497 1.19382H158.056C158.269 1.19382 158.428 1.24707 158.535 1.32695C158.641 1.43345 158.721 1.53995 158.721 1.72633C158.721 1.96595 158.615 2.12571 158.428 2.20558L158.854 2.87121H158.508L158.136 2.25883H157.816V2.87121H157.523V1.19382H157.497ZM157.789 1.99258H158.029C158.162 1.99258 158.269 1.96595 158.322 1.93933C158.375 1.88608 158.402 1.83283 158.402 1.72633C158.402 1.53995 158.269 1.46007 158.029 1.46007H157.789V1.99258Z"
                fill="currentColor"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_72_838">
              <rect
                width="160"
                height="24"
                fill="white"
                transform="translate(0 0.0236816)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};
