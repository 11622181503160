import { Button } from "../components/button";
import { Contact } from "../components/contact";
import { Footer } from "../components/footer";
import { GetStarted } from "../components/getstarted";
import phone from "../assets/subscribe/phone.png";
import paperplane from "../assets/subscribe/paperplane.png";
import mail from "../assets/subscribe/mail.png";
import facebook from "../assets/subscribe/facebook.png";
import instagram from "../assets/subscribe/instagram.png";
import twitter from "../assets/subscribe/twitter.png";
import Header from "../components/Header";
import { FAQ } from "../components/faq";

export default function Subscription() {
  return (
    <div>
      <Header />
      <div className="relative md:px-[100px] px-5 pb-[92px] pt-[140px] flex flex-col gap-7 items-center bg-[#F7F7FD] text-[#1D2130]">
        <p className="text-[24px] leading-[120%] roboto-bold text-[#CD2C34]">
          Subscription Options
        </p>
        <p className="w-[640px] text-[48px] leading-[120%] text-center roboto-medium text-[#1D2130]">
          Affordable Prices, Powerful Solutions
        </p>
        <div className="grid md:grid-cols-2 w-full md:gap-1">
          <div className="bg-white md:hidden flex flex-col gap-[60px] pl-[50px] py-[68px] md:rounded-r-[20px] max-md:rounded-t-[20px] shadow-[0px_80.6px_105.7px_0px_#3333331a]">
            <p className="text-[48px] leading-[120%] roboto-medium text-[#1D2130]">
              Get in touch
            </p>
            <div className="flex flex-col gap-9">
              <div className="flex flex-row gap-6 items-center">
                <img src={phone} />
                <div className="flex flex-col gap-1">
                  <p className="text-[24px] leading-[140%] roboto-bold text-[#1D2130]">
                    + 1800 145 276
                  </p>
                  <p className="text-[16px] leading-[150%] roboto-regular text-[#1d2130] opacity-60">
                    Free support
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-6 items-center">
                <img src={paperplane} />
                <div className="flex flex-col gap-1">
                  <p className="text-[24px] leading-[140%] roboto-bold text-[#1D2130]">
                    aisystemics@gmail.com
                  </p>
                  <p className="text-[16px] leading-[150%] roboto-regular text-[#1d2130] opacity-60">
                    Help Email support
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-6 items-center">
                <img src={mail} />
                <div className="flex flex-col gap-1">
                  <p className="text-[24px] leading-[140%] roboto-bold text-[#1D2130]">
                    sales@aisystemics.com
                  </p>
                  <p className="text-[16px] leading-[150%] roboto-regular text-[#1d2130] opacity-60">
                    Sales Enquiry
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-5">
              <a href="#" target="_blank">
                <img src={facebook} />
              </a>
              <a href="#" target="_blank">
                <img src={instagram} />
              </a>
              <a href="#" target="_blank">
                <img src={twitter} />
              </a>
            </div>
          </div>
          <div className="bg-white flex flex-col gap-5 px-[40px] py-[48px] md:rounded-l-[20px] max-md:rounded-b-[20px] shadow-[0px_80.6px_105.7px_0px_#3333331a]">
            <input
              className="lg:w-[484px] text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
              placeholder="Your Name (required)"
            />
            <input
              className="lg:w-[484px] text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
              placeholder="Your Email (required)"
            />
            <input
              className="lg:w-[484px] text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
              placeholder="Mobile Number"
            />
            <textarea
              className="lg:w-[484px] h-[165px] resize-none text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
              placeholder="Your Message"
            />
            <div className="flex flex-row gap-3">
              <input
                type="checkbox"
                id="accept"
                className="w-[22px] h-[22px]"
              />
              <label
                htmlFor="accept"
                className="text-[#303030] roboto-regular text-[16px]"
              >
                He read and accepted the privacy policy.
              </label>
            </div>
            <div>
              <Button
                className="robot-regular text-[14px] py-[10px] px-[60px] rounded-[10px] w-[155px] h-[50px]"
                color="danger"
              >
                Send
              </Button>
            </div>
          </div>
          <div className="bg-white max-md:hidden flex flex-col gap-[60px] pl-[50px] py-[68px] md:rounded-r-[20px]  shadow-[0px_80.6px_105.7px_0px_#3333331a]">
            <p className="text-[48px] leading-[120%] roboto-medium text-[#1D2130]">
              Get in touch
            </p>
            <div className="flex flex-col gap-9">
              <div className="flex flex-row gap-6 items-center">
                <img src={phone} />
                <div className="flex flex-col gap-1">
                  <p className="text-[24px] leading-[140%] roboto-bold text-[#1D2130]">
                    + 1800 145 276
                  </p>
                  <p className="text-[16px] leading-[150%] roboto-regular text-[#1d2130] opacity-60">
                    Free support
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-6 items-center">
                <img src={paperplane} />
                <div className="flex flex-col gap-1">
                  <p className="text-[24px] leading-[140%] roboto-bold text-[#1D2130]">
                    aisystemics@gmail.com
                  </p>
                  <p className="text-[16px] leading-[150%] roboto-regular text-[#1d2130] opacity-60">
                    Help Email support
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-6 items-center">
                <img src={mail} />
                <div className="flex flex-col gap-1">
                  <p className="text-[24px] leading-[140%] roboto-bold text-[#1D2130]">
                    sales@aisystemics.com
                  </p>
                  <p className="text-[16px] leading-[150%] roboto-regular text-[#1d2130] opacity-60">
                    Sales Enquiry
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-5">
              <a href="#" target="_blank">
                <img src={facebook} />
              </a>
              <a href="#" target="_blank">
                <img src={instagram} />
              </a>
              <a href="#" target="_blank">
                <img src={twitter} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
      <Contact />
      <FAQ />
      <Footer />
    </div>
  );
}
