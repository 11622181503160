export const Robinhood = () => {
  return (
    <div>
      <div className="max-md:hidden">
        <svg
          width="98"
          height="20"
          viewBox="0 0 98 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_72_922)">
            <path
              d="M34.5263 6.66016C31.9688 6.66016 30.4017 8.33741 30.4017 11.2853C30.4017 14.2332 31.9688 15.8978 34.5263 15.8978C37.0838 15.8978 38.6384 14.2205 38.6384 11.2853C38.6384 8.35012 37.1089 6.66016 34.5263 6.66016ZM34.5263 14.5509C32.9091 14.5509 32.2572 13.204 32.2572 11.298C32.2697 9.36664 32.9091 8.03246 34.5263 8.03246C36.1436 8.03246 36.7955 9.36664 36.7955 11.2853C36.7955 13.204 36.1436 14.5509 34.5263 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M57.9575 6.66016C56.6913 6.66016 55.9516 7.24465 55.4 7.99434C55.3248 8.08328 55.2621 8.17223 55.1367 8.17223C55.0114 8.17223 54.9111 8.08328 54.9111 7.90539V6.83805H53.2562V15.7326H55.0114V10.8279C55.0114 9.23957 55.8513 8.1087 57.1677 8.1087C58.4088 8.1087 59.0231 8.83297 59.0231 10.4086V15.7326H60.7783V9.93843C60.7783 7.89268 59.8631 6.66016 57.9575 6.66016Z"
              fill="currentColor"
            />
            <path
              d="M67.3351 6.6602C66.1566 6.6602 65.3919 7.19387 64.8779 7.86731C64.8027 7.95626 64.74 8.04521 64.6146 8.04521C64.4892 8.04521 64.3889 7.95626 64.3889 7.77837V3.00073H62.6338V15.7326H64.3889V10.8279C64.3889 9.23961 65.2289 8.10874 66.5453 8.10874C67.7864 8.10874 68.4007 8.83301 68.4007 10.4086V15.7326H70.1559V9.93847C70.1559 7.89273 69.2407 6.6602 67.3351 6.6602Z"
              fill="currentColor"
            />
            <path
              d="M75.6344 6.66016C73.0769 6.66016 71.5098 8.33741 71.5098 11.2853C71.5098 14.2332 73.0769 15.8978 75.6344 15.8978C78.1919 15.8978 79.7464 14.2205 79.7464 11.2853C79.7339 8.33741 78.2169 6.66016 75.6344 6.66016ZM75.6344 14.5509C74.0171 14.5509 73.3652 13.204 73.3652 11.298C73.3652 9.39205 74.0171 8.04516 75.6344 8.04516C77.2516 8.04516 77.9035 9.37934 77.9035 11.298C77.891 13.1913 77.2516 14.5509 75.6344 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M84.8364 6.66016C82.2789 6.66016 80.7118 8.33741 80.7118 11.2853C80.7118 14.2332 82.2789 15.8978 84.8364 15.8978C87.3939 15.8978 88.9485 14.2205 88.9485 11.2853C88.9485 8.35012 87.419 6.66016 84.8364 6.66016ZM84.8364 14.5509C83.2192 14.5509 82.5672 13.204 82.5672 11.298C82.5672 9.39205 83.2192 8.04516 84.8364 8.04516C86.4536 8.04516 87.1055 9.37934 87.1055 11.298C87.1055 13.2167 86.4536 14.5509 84.8364 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M51.3506 6.83813H49.5955V15.7327H51.3506V6.83813Z"
              fill="currentColor"
            />
            <path
              d="M51.4008 3.00073H49.5203V5.03377H51.4008V3.00073Z"
              fill="currentColor"
            />
            <path
              d="M96.2449 3.00073V7.54965C96.2449 7.72754 96.1446 7.81649 96.0192 7.81649C95.9315 7.81649 95.8061 7.72754 95.7559 7.67672C95.1291 7.00327 94.4521 6.6602 93.3865 6.6602C91.4934 6.6602 89.9514 8.13415 89.9514 11.2854C89.9514 14.4366 91.4934 15.8978 93.3865 15.8978C94.4897 15.8978 95.1793 15.4658 95.8437 14.7923C95.944 14.6907 96.0192 14.6272 96.107 14.6272C96.2323 14.6272 96.3326 14.7161 96.3326 14.894V15.7326H98V3.00073H96.2449ZM94.0509 14.5382C92.4337 14.5509 91.7818 13.1913 91.7818 11.2854C91.7818 9.37939 92.4337 8.0325 94.0509 8.0325C95.6682 8.0325 96.3201 9.36668 96.3201 11.2854C96.3201 13.204 95.6933 14.5382 94.0509 14.5382Z"
              fill="currentColor"
            />
            <path
              d="M44.7062 6.6602C43.6406 6.6602 42.9636 7.00327 42.3367 7.67672C42.2866 7.72754 42.1612 7.81649 42.0735 7.81649C41.9481 7.81649 41.8478 7.72754 41.8478 7.54965V3.00073H40.0927V15.7326H41.7475V14.894C41.7475 14.7161 41.8478 14.6272 41.9732 14.6272C42.0609 14.6272 42.1361 14.678 42.2364 14.7923C42.9009 15.4658 43.5904 15.8978 44.6937 15.8978C46.5867 15.8978 48.1413 14.4366 48.1413 11.2854C48.1413 8.13415 46.5992 6.6602 44.7062 6.6602ZM44.0292 14.5509C42.3994 14.5509 41.76 13.204 41.76 11.2981C41.76 9.39209 42.3869 8.04521 44.0292 8.04521C45.6715 8.04521 46.2984 9.37939 46.2984 11.2981C46.2984 13.1913 45.6464 14.5509 44.0292 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M27.6436 10.0657C27.5057 10.0148 27.4555 9.92588 27.4555 9.81153C27.4555 9.68446 27.5057 9.63364 27.6687 9.54469C28.6215 9.08726 29.1981 8.17239 29.1981 6.92716C29.1981 4.74164 27.8442 3.42017 25.1237 3.42017H20.4349V15.7327H22.2904V10.6502H25.2867C26.7033 10.6502 27.1797 11.2601 27.2173 12.2512L27.3928 15.7327H29.2232L29.0352 12.1241C28.9599 10.9297 28.4961 10.3833 27.6436 10.0657ZM24.9983 9.18891H22.2904V4.91953H24.9983C26.4024 4.91953 27.2675 5.61839 27.2675 7.05422C27.2675 8.49005 26.4024 9.18891 24.9983 9.18891Z"
              fill="currentColor"
            />
            <path
              d="M9.27218 4.30957H5.461C5.3231 4.30957 5.2065 4.36548 5.10997 4.48746L2.37695 7.91821C1.97578 8.42647 1.87548 8.89661 1.87548 9.57005V13.077C0.985372 15.6056 0.421217 17.321 0.00750343 18.8712C-0.0175701 18.9703 0.0200402 19.0237 0.107798 19.0237H0.521511C0.596732 19.0237 0.671953 18.9856 0.697026 18.922C3.81868 10.8661 7.21615 6.87628 9.3474 4.48746C9.43516 4.38581 9.39755 4.30957 9.27218 4.30957Z"
              fill="currentColor"
            />
            <path
              d="M9.38505 0.357769C9.1293 0.45815 8.88358 0.628417 8.77075 0.726257C7.64244 1.70466 6.89023 2.47975 6.17563 3.24214C6.08788 3.33109 6.12549 3.42003 6.25086 3.42003H10.4757C10.8644 3.42003 11.0901 3.64875 11.0901 4.04265V8.87111C11.0901 8.99818 11.1903 9.03629 11.2656 8.92194L13.8105 5.55472C14.2242 5.00834 14.3496 4.84316 14.4624 4.08077C14.6129 2.9626 14.5251 1.24722 13.8607 0.53566C13.2715 -0.099664 10.6137 -0.125077 9.38505 0.357769Z"
              fill="currentColor"
            />
            <path
              d="M10.0245 5.27527C7.40428 8.23588 5.36079 11.349 3.46773 15.0974C3.42009 15.1914 3.48027 15.2753 3.5931 15.2371L7.50458 14.0173C7.94587 13.8801 8.1941 13.6997 8.40722 13.3439L10.1498 10.4341C10.1874 10.3579 10.2 10.2689 10.2 10.2054V5.35151C10.2 5.22444 10.1122 5.17362 10.0245 5.27527Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_72_922">
              <rect
                width="98"
                height="19"
                fill="white"
                transform="translate(0 0.0236816)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="md:hidden">
        <svg
          width="63"
          height="24"
          viewBox="0 0 98 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_72_922)">
            <path
              d="M34.5263 6.66016C31.9688 6.66016 30.4017 8.33741 30.4017 11.2853C30.4017 14.2332 31.9688 15.8978 34.5263 15.8978C37.0838 15.8978 38.6384 14.2205 38.6384 11.2853C38.6384 8.35012 37.1089 6.66016 34.5263 6.66016ZM34.5263 14.5509C32.9091 14.5509 32.2572 13.204 32.2572 11.298C32.2697 9.36664 32.9091 8.03246 34.5263 8.03246C36.1436 8.03246 36.7955 9.36664 36.7955 11.2853C36.7955 13.204 36.1436 14.5509 34.5263 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M57.9575 6.66016C56.6913 6.66016 55.9516 7.24465 55.4 7.99434C55.3248 8.08328 55.2621 8.17223 55.1367 8.17223C55.0114 8.17223 54.9111 8.08328 54.9111 7.90539V6.83805H53.2562V15.7326H55.0114V10.8279C55.0114 9.23957 55.8513 8.1087 57.1677 8.1087C58.4088 8.1087 59.0231 8.83297 59.0231 10.4086V15.7326H60.7783V9.93843C60.7783 7.89268 59.8631 6.66016 57.9575 6.66016Z"
              fill="currentColor"
            />
            <path
              d="M67.3351 6.6602C66.1566 6.6602 65.3919 7.19387 64.8779 7.86731C64.8027 7.95626 64.74 8.04521 64.6146 8.04521C64.4892 8.04521 64.3889 7.95626 64.3889 7.77837V3.00073H62.6338V15.7326H64.3889V10.8279C64.3889 9.23961 65.2289 8.10874 66.5453 8.10874C67.7864 8.10874 68.4007 8.83301 68.4007 10.4086V15.7326H70.1559V9.93847C70.1559 7.89273 69.2407 6.6602 67.3351 6.6602Z"
              fill="currentColor"
            />
            <path
              d="M75.6344 6.66016C73.0769 6.66016 71.5098 8.33741 71.5098 11.2853C71.5098 14.2332 73.0769 15.8978 75.6344 15.8978C78.1919 15.8978 79.7464 14.2205 79.7464 11.2853C79.7339 8.33741 78.2169 6.66016 75.6344 6.66016ZM75.6344 14.5509C74.0171 14.5509 73.3652 13.204 73.3652 11.298C73.3652 9.39205 74.0171 8.04516 75.6344 8.04516C77.2516 8.04516 77.9035 9.37934 77.9035 11.298C77.891 13.1913 77.2516 14.5509 75.6344 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M84.8364 6.66016C82.2789 6.66016 80.7118 8.33741 80.7118 11.2853C80.7118 14.2332 82.2789 15.8978 84.8364 15.8978C87.3939 15.8978 88.9485 14.2205 88.9485 11.2853C88.9485 8.35012 87.419 6.66016 84.8364 6.66016ZM84.8364 14.5509C83.2192 14.5509 82.5672 13.204 82.5672 11.298C82.5672 9.39205 83.2192 8.04516 84.8364 8.04516C86.4536 8.04516 87.1055 9.37934 87.1055 11.298C87.1055 13.2167 86.4536 14.5509 84.8364 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M51.3506 6.83813H49.5955V15.7327H51.3506V6.83813Z"
              fill="currentColor"
            />
            <path
              d="M51.4008 3.00073H49.5203V5.03377H51.4008V3.00073Z"
              fill="currentColor"
            />
            <path
              d="M96.2449 3.00073V7.54965C96.2449 7.72754 96.1446 7.81649 96.0192 7.81649C95.9315 7.81649 95.8061 7.72754 95.7559 7.67672C95.1291 7.00327 94.4521 6.6602 93.3865 6.6602C91.4934 6.6602 89.9514 8.13415 89.9514 11.2854C89.9514 14.4366 91.4934 15.8978 93.3865 15.8978C94.4897 15.8978 95.1793 15.4658 95.8437 14.7923C95.944 14.6907 96.0192 14.6272 96.107 14.6272C96.2323 14.6272 96.3326 14.7161 96.3326 14.894V15.7326H98V3.00073H96.2449ZM94.0509 14.5382C92.4337 14.5509 91.7818 13.1913 91.7818 11.2854C91.7818 9.37939 92.4337 8.0325 94.0509 8.0325C95.6682 8.0325 96.3201 9.36668 96.3201 11.2854C96.3201 13.204 95.6933 14.5382 94.0509 14.5382Z"
              fill="currentColor"
            />
            <path
              d="M44.7062 6.6602C43.6406 6.6602 42.9636 7.00327 42.3367 7.67672C42.2866 7.72754 42.1612 7.81649 42.0735 7.81649C41.9481 7.81649 41.8478 7.72754 41.8478 7.54965V3.00073H40.0927V15.7326H41.7475V14.894C41.7475 14.7161 41.8478 14.6272 41.9732 14.6272C42.0609 14.6272 42.1361 14.678 42.2364 14.7923C42.9009 15.4658 43.5904 15.8978 44.6937 15.8978C46.5867 15.8978 48.1413 14.4366 48.1413 11.2854C48.1413 8.13415 46.5992 6.6602 44.7062 6.6602ZM44.0292 14.5509C42.3994 14.5509 41.76 13.204 41.76 11.2981C41.76 9.39209 42.3869 8.04521 44.0292 8.04521C45.6715 8.04521 46.2984 9.37939 46.2984 11.2981C46.2984 13.1913 45.6464 14.5509 44.0292 14.5509Z"
              fill="currentColor"
            />
            <path
              d="M27.6436 10.0657C27.5057 10.0148 27.4555 9.92588 27.4555 9.81153C27.4555 9.68446 27.5057 9.63364 27.6687 9.54469C28.6215 9.08726 29.1981 8.17239 29.1981 6.92716C29.1981 4.74164 27.8442 3.42017 25.1237 3.42017H20.4349V15.7327H22.2904V10.6502H25.2867C26.7033 10.6502 27.1797 11.2601 27.2173 12.2512L27.3928 15.7327H29.2232L29.0352 12.1241C28.9599 10.9297 28.4961 10.3833 27.6436 10.0657ZM24.9983 9.18891H22.2904V4.91953H24.9983C26.4024 4.91953 27.2675 5.61839 27.2675 7.05422C27.2675 8.49005 26.4024 9.18891 24.9983 9.18891Z"
              fill="currentColor"
            />
            <path
              d="M9.27218 4.30957H5.461C5.3231 4.30957 5.2065 4.36548 5.10997 4.48746L2.37695 7.91821C1.97578 8.42647 1.87548 8.89661 1.87548 9.57005V13.077C0.985372 15.6056 0.421217 17.321 0.00750343 18.8712C-0.0175701 18.9703 0.0200402 19.0237 0.107798 19.0237H0.521511C0.596732 19.0237 0.671953 18.9856 0.697026 18.922C3.81868 10.8661 7.21615 6.87628 9.3474 4.48746C9.43516 4.38581 9.39755 4.30957 9.27218 4.30957Z"
              fill="currentColor"
            />
            <path
              d="M9.38505 0.357769C9.1293 0.45815 8.88358 0.628417 8.77075 0.726257C7.64244 1.70466 6.89023 2.47975 6.17563 3.24214C6.08788 3.33109 6.12549 3.42003 6.25086 3.42003H10.4757C10.8644 3.42003 11.0901 3.64875 11.0901 4.04265V8.87111C11.0901 8.99818 11.1903 9.03629 11.2656 8.92194L13.8105 5.55472C14.2242 5.00834 14.3496 4.84316 14.4624 4.08077C14.6129 2.9626 14.5251 1.24722 13.8607 0.53566C13.2715 -0.099664 10.6137 -0.125077 9.38505 0.357769Z"
              fill="currentColor"
            />
            <path
              d="M10.0245 5.27527C7.40428 8.23588 5.36079 11.349 3.46773 15.0974C3.42009 15.1914 3.48027 15.2753 3.5931 15.2371L7.50458 14.0173C7.94587 13.8801 8.1941 13.6997 8.40722 13.3439L10.1498 10.4341C10.1874 10.3579 10.2 10.2689 10.2 10.2054V5.35151C10.2 5.22444 10.1122 5.17362 10.0245 5.27527Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_72_922">
              <rect
                width="98"
                height="19"
                fill="white"
                transform="translate(0 0.0236816)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};
