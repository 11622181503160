import '../assets/css/styles.css';
import { useState } from "react";
import axiosInstance from "../utils/axios";
import { toast } from "react-toastify";
import logo from "../assets/signup/logo.png";
import hero from "../assets/signup/hero.png";
import { Button } from "../components/button";
import { Link } from "react-router-dom";


function Login() {

  const [formData, setFormData] = useState({
      username: '',
      password: '',
  });
  const [errors, setErrors] = useState({});
  
  const [waiting, setWaiting] = useState(false);

  const handleChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value,
      });
      setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      const tempErrors = {};
      
      // Basic validation
      if (!formData.username.includes('@') && formData.username.length < 5) {
        tempErrors.username = 'Please enter a valid email or username with at least 5 characters.';
      }
      if (formData.password.length < 6) {
        tempErrors.password = 'Password must be at least 6 characters long.';
      }
  
      setErrors(tempErrors);
  
      if (Object.keys(tempErrors).length > 0) {
        return;
      }
      // Handle form submission and Stripe payment here
      try {
        setWaiting(true);
        const response = await axiosInstance.post('/login', {
            ...formData,
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true
        });
        setWaiting(false);
        const result = response.data;
        console.log(result);
        if(result.msg)
            toast.success(result.msg)
        window.location.href = process.env.REACT_APP_BACKEND_URL
      }
      catch(error) {
        setWaiting(false);
        if (error.response) {
          toast.error(error.response.data.msg);
        } else {
          console.error('Error:', error.message);
        }
      }
  };

  const isReadyForSubmit = () => {
    return formData.username.length > 0 && formData.password.length > 0
  }

  return (
    <div className="flex flex-row ">
      <div className="grow my-auto ">
        <div className="max-w-[630px] mx-auto flex flex-col items-center gap-[43px] px-5">
          <img src={logo} />
          <p className="md:text-[48px] text-[32px] leading-[120%] max-md:text-center text-[#1D2130] roboto-medium text-center">
          Log in to access your dashboard
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col gap-[30px] w-full">
            <div className="flex flex-col gap-[20px] ">
              <input
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
                className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                placeholder="Email"
              />
              {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
              <input
                className="w-full text-[16px] outline-none roboto-regular px-6 py-[18px] rounded-[5px] border border-[#0A142F4d] text-base text-[#0A142F] placeholder:text-[#0A142F]"
                placeholder="Password"
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
              <Link
                to="/password-reset"
                className="text-[#303030] transition-all roboto-regular"
              >
                Forgot your password?
              </Link>
            </div>
            <div className="flex flex-col gap-4">
              <Button
              type="submit"
                className="robot-regular text-[14px] py-[10px] px-[60px] rounded-[10px]  h-[50px] shadow-[0px_80.6px_105.7px_0px_#3333331a]"
                color="danger"
                disabled={!isReadyForSubmit() || waiting}
              >
                { waiting ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                      ></path>
                    </svg>
                  ) : (
                    'Log In'
                  )}
              </Button>
              <p className="text-[16px] roboto-regular text-[#303030] text-center">
                OR
              </p>
              <Button
                className="robot-regular text-[14px] py-[10px] px-[60px] rounded-[10px]  h-[50px] shadow-[0px_80.6px_105.7px_0px_#3333331a]"
                color="blue"
              >
                Continue with Facebook
              </Button>
              <Button
                className="robot-regular text-[14px] py-[10px] px-[60px] rounded-[10px]  h-[50px] shadow-[0px_80.6px_105.7px_0px_#3333331a]"
                color="white"
              >
                Continue with <span className="text-[#3F82F8]">G</span>
                <span className="text-[#EF3F2F]">o</span>
                <span className="text-[#FEBD00]">o</span>
                <span className="text-[#3F82F8]">g</span>
                <span className="text-[#2DA951]">l</span>
                <span className="text-[#EF3F2F]">e</span>
              </Button>
            </div>
            <p className="text-[16px] roboto-regular text-[#303030] text-center">
            Not a AI Systemic’s member yet?{" "}
              <Link
                to="/signup"
                className="text-[#CD2C34] transition-all hover:text-[#db575d] roboto-bold"
              >
                Sign up
              </Link>
              <br />
              Are you a business? Get started here!
            </p>
          </form>
        </div>
      </div>
      <img src={hero} className="h-[100vh] max-lg:hidden" />
    </div>
  );
}

export default Login;
