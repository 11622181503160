import { useNavigate } from "react-router-dom";
import trading from "../assets/trading.png";
import { Button } from "./button";
import { CircleArrowRight } from "./icons/circlearrowright";
export const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <div className="md:px-[100px] px-5 py-[40px] flex flex-col bg-[#F7F7FD] text-[#1D2130]">
      <div className="flex flex-col md:h-[214px] h-[353px] overflow-hidden relative">
        <img src={trading} className="h-[353px] object-cover"/>
        <div className="absolute md:px-[60px] md:py-[50px] py-4 left-0 top-0 w-full h-full flex md:flex-row flex-col justify-between items-center">
          <p className="roboto-regular md:text-[40px] max-md:px-5 md:leading-[139%] text-[32px] leading-[44.48px] max-md:text-center text-white max-w-[770px]">
            Power, Precision, Protection – The Features You Need for Confident
            Trading.
          </p>
          <div>
            <Button
            onClick={() => navigate('/login')}
              color="danger"
              className="flex flex-row rounded-[10px] py-[10px] pr-[10px] pl-[25px] roboto-regular items-center gap-[10px]"
            >
              Get Started
              <CircleArrowRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
