import Header from "../components/Header";

import usergroup from "../assets/usergroup.png";
import arrowleft from "../assets/nextstep.png";
import sharelink from "../assets/sharelink.png";
import earnreward from "../assets/earnreward.png";
import { GetStarted } from "../components/getstarted";
import { Contact } from "../components/contact";
import { Footer } from "../components/footer";
import { FAQ } from "../components/faq";

export default function Referrals() {
  return (
    <div>
      <Header />
      <div
        id="referrals"
        className="relative md:px-[100px] px-5 pb-[92px] pt-[60px] md:pt-[140px] flex flex-col gap-10 bg-[#F7F7FD] text-[#1D2130]"
      >
        <div className="flex flex-col gap-[6px] max-w-[740px] mx-auto">
          <p className="roboto-medium md:text-[48px] md:leading-[57.6px] text-[32px] leading-[38.4px] text-center">
            Earn Rewards by Sharing Success
          </p>
          <p className="px-[21px] roboto-regular md:text-[16px] md:leading-[24px] text-[14px] leading-[21px] text-center">
            At AI Systemics, your success grows as others succeed. Join our
            Referral Program and start earning by introducing others to our
            platform.
          </p>
        </div>
        <div className="md:grid md:grid-cols-3 max-md:flex max-md:flex-col gap-5">
          <div className="bg-[#F7F7FD] rounded-[10px] md:px-[50px] px-5 text-[#1D2130] h-[380px] flex flex-col justify-center items-center relative">
            <img src={usergroup} />
            <img
              src={arrowleft}
              className="max-md:w-[60px] md:right-0 md:translate-x-[50%] md:top-[90px] max-md:rotate-90 max-md:bottom-[0] max-md:translate-y-[50%] max-md:translate-x-[-50%] left-[50%] absolute z-10"
            />
            <p className="roboto-bold md:text-[22px] md:leading-[140%] text-[20px] leading-[28px] md:mt-[30px] mt-5 text-center">
              Receive a Unique Referral Link
            </p>
            <p className="px-[21px] roboto-regular md:text-[16px] mt-4 md:leading-[24px] text-[14px] leading-[21px] text-center">
              Each client receives a personalized referral link that can be
              easily shared with friends, colleagues, or anyone interested in
              algorithmic trading.
            </p>
          </div>
          <div className="bg-[#F7F7FD] rounded-[10px] md:px-[50px] px-5 text-[#1D2130] h-[380px] flex flex-col justify-center items-center relative">
            <img src={sharelink} />
            <img
              src={arrowleft}
              className="max-md:w-[60px] md:right-0 md:translate-x-[50%] md:top-[90px] max-md:rotate-90 max-md:bottom-[0] max-md:translate-y-[50%] max-md:translate-x-[-50%] left-[50%] absolute z-10"
            />
            <p className="roboto-bold md:text-[22px] md:leading-[140%] text-[20px] leading-[28px] md:mt-[30px] mt-5 max-md:text-center">
              Share Your Link
            </p>
            <p className="roboto-regular md:text-[16px] text-[14px] leading-[26px] text-center mt-4">
              Spread the word! Easily share your referral link via email, social
              media, or messaging apps to invite others to experience AI
              Systemics' trading solutions.
            </p>
          </div>
          <div className="bg-[#F7F7FD] rounded-[10px] md:px-[50px] px-5 text-[#1D2130] h-[380px] flex flex-col justify-center items-center relative">
            <img src={earnreward} />
            <p className="roboto-bold md:text-[22px] md:leading-[140%] text-[20px] leading-[28px] md:mt-[30px] mt-5 max-md:text-center">
              Earn Rewards
            </p>
            <p className="roboto-regular md:text-[16px] text-[14px] leading-[26px] text-center mt-4">
              Once a client joins using your link, you start earning a
              percentage of the profits from each trade your referral makes.
              Your success grows as their trading journey thrives!
            </p>
          </div>
        </div>
      </div>
      <GetStarted />
      <Contact />
      <FAQ />
      <Footer />
    </div>
  );
}
