import { ReactNode } from "react";

export const Button = ({ type, className, color, children, onClick, disabled }) => {
  const bgColors = {
    primary: "bg-[#182A3E] hover:bg-[#477cb8] text-white",
    danger: "bg-[#CD2C34] hover:bg-[#db575d] text-white",
    secondary: "bg-[#1D2130] hover:bg-[#303750] text-white",
    blue: "bg-[#0866FF] hover:bg-[#3381ff] text-white",
    white: "bg-[#fff] hover:bg-[#eee] text-black",
  };
  return (
    <button
    type={type}
      onClick={onClick}
      disabled={disabled}
      className={`  ${bgColors[color]} transition-all ${className}  `}
    >
      {children}
    </button>
  );
};
